<template>
  <div class="aboutMaxBox" style="height: 105vw;width: 100vw;background: #fdfaec;margin-top: -1vw;">
    <div >
      <img src="@/assets/image/bottomBackground.png" class="bottomBackground" alt="">
      <img src="@/assets/image/zhen.png" class="zhen"  alt="">
      <img src="@/assets/image/zongseBottomBackground.png" class="zongse" alt="">
      <img src="@/assets/image/witerBackground.png" class="whiter" alt="">
      <div class="textContent">
        <p class="title" style="font-size: 1vw;font-weight: 600;color: #fff;">{{ title }}</p>
        <p class="titles" style="right: 25vw;float: right;color: #fff;transition: all 1s ease-out;">TOP › プライバシーポリシー</p>
        <div class="content">
          <div v-for="item in contentText" :key="item" style="display: block;">
            <p class="contentAll"
              :style="`font-size: .7vw;margin-top:${item.indexOf('■') > -1 || item.indexOf('・') > -1 ? 2 : 0}vw`">{{
                item }}</p>
          </div>
          <p style="top: 2vw;right: -30vw;font-size: .7vw;">Nagis Choice</p>
        </div>
      </div>
      <index_bottom style="top: 30vw;" />
    </div>
  </div>
</template>
<style scoped>
.titles{
  opacity: 0;
}
img{
  transition: all 1s cubic-bezier(0, -0.06, .04, .83);
}
.zhen{
  width: 2vw;
    left: 20vw;
    top: 9vw;
    z-index: 99;
    position: absolute !important;
    transform: translateY(-10vw);
}
.line{
  width: 10vw;
  top: -14vw;
}
.icon {
  width: 1vw;
  position: relative;
  top: .3vw;
}
.contentAll {
  opacity: 0;
  filter: blur(.3vw);
  transition: all .5s ease;
}

.content {
  text-align: left;
  transform: rotateZ(1deg);
  left: 33vw;
  top: 10vw;
}

.textContent {
  text-align: center;
  top: 15vw;
}

.aboutMaxBox * {
  position: relative;
}

.bottomBackground {
  width: 70vw;
  position: absolute;
  top: 10vw;
  /* margin: 0 auto; */
  left: 15vw;
  transform: rotateZ(0deg) ;
}

.zongse {
  width: 69vw;
  position: absolute;
  top: 12vw;
  transform: rotateZ(1deg) translateY(3vw) translateX(3vw);
  left: 16vw;
}

.whiter {
  width: 50vw;
  position: absolute;
  top: 20vw;
  transform: rotateZ(2deg) translateY(5vw) translateX(5vw);
  left: 25vw;
}
</style>
<script>
import contData from "@/components/content";
import index_bottom from "./index_bottom.vue";

export default {
  name: 'about_agreementName',
  components:{
    index_bottom
  },
  props: {
  },
  data() {
    return {
      contentText: contData,
      title:'',
    }
  },
  mounted() {
    document.body.style.height = '114vw'
    window.scrollTo({
      top: 0,
      behavior: 'auto' // 平滑滚动
    });
    const title = 'プライバシ-ボりシ-'
    let i = 0
   const setId = setInterval(() => {
      this.title += title[i]
      i++
      if(i>=title.length){
        clearInterval(setId)
        i=null
      }
    }, 100 );
    // this.contentText.map((item)=>{})
  const dom1 = document.getElementsByClassName('zongse')[0]
  const dom2 = document.getElementsByClassName('bottomBackground')[0]
  const dom3 = document.getElementsByClassName('whiter')[0]
  const dom4 = document.getElementsByClassName('zhen')[0]
  const dom5 = document.getElementsByClassName('titles')[0]
  dom1.style.transform='rotateZ(0)'
  dom2.style.transform='rotateZ(1deg) translateY(0vw) translateX(0vw)'
  dom3.style.transform='rotateZ(1deg) translateY(0vw) translateX(0vw)'
  dom4.style.transform='translateY(0)'
  dom5.style.opacity='1'

   setTimeout(() => {
    const dom = document.getElementsByClassName('contentAll')
    Object.values(dom).map((item, index) => {
      setTimeout(() => {
        item.style.opacity = 1
        item.style.filter = 'blur(0vw)'
      }, index * 500)
    })
   }, 1000);
  },
  methods: {
  }
}
</script>
