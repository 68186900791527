<template>
    <!-- <div class="viewboxT">
        <div class="scrollboxT"> -->
    <div style="width: 100vw;background: #fdfaec;">
        <el-row style="height:60vw;overflow: hidden">
            <el-col :span="15">
                <div style="margin-left: 25vw;">
                    <div class="form" style="width: 34vw;">
                        <img src="@/assets/image/xinBackground.png"
                            style="width: 34vw;position: absolute;z-index: 1;top: 5vw" alt="">
                        <div class="text" style="z-index: 2;position: relative;top: 5vw;">
                            <p
                                style="font-size: .6vw;font-weight: 600;color: #fff;text-shadow: 0vw .1vw .2vw rgba(0,0,0,.5);margin: 2vw 3vw;">
                                抱えている思いをぜひお聞かせください、<br> 「自分が好き」と思えるモノに出会えるまで。</p>
                            <p style="font-size: .6vw;margin: -1vw 3vw;color: #978e80">
                                Until you find your favorite, <br> please share with uswhat you car
                            </p>
                        </div>
                        <div class="formContent" style="z-index: 2;position: relative;margin: 10vw 0 2vw 3vw">
                            <el-row style="position: relative;">
                                <el-col :span="7" style="font-size: .7vw;">お名前<img src="@/assets/image/mastIcon.png"
                                        style="margin:-.1vw 1vw;height: .7vw;" alt=""></el-col>
                                <el-col :span="17"><input type="text"
                                        style="width: 18vw;margin-top: -1vw;height: 2vw;border-radius: .3vw;background: #f2ede4;border: none;position: relative;top: -.6vw"></el-col>
                            </el-row>
                            <el-row style="position: relative;top: 4vw">
                                <el-col :span="7" style="font-size: .7vw;">メールアドレス<img src="@/assets/image/mastIcon.png"
                                        style="margin:-.1vw 1vw;height: .7vw;" alt=""></el-col>
                                <el-col :span="17"><input type="text"
                                        style="width: 18vw;margin-top: -1vw;height: 2vw;border-radius: .3vw;background: #f2ede4;border: none;position: relative;top: -.6vw"></el-col>
                            </el-row>
                            <el-row style="position: relative;top: 8vw">
                                <el-col :span="7" style="font-size: .7vw;">お問い合わせ内容</el-col>
                                <el-col :span="17">
                                    <textarea name="mark" id="mark" rows="15" style="width: 18vw"></textarea>
                                    <!-- <input type="textarea" -->
                                    <!-- style="width: 18vw;margin-top: -1vw;height: 2vw;border-radius: .3vw;background: #f2ede4;border: none;position: relative;top: -.6vw"> -->
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                    <div style="z-index: 99;position: absolute;width: 34vw;top: 48.6vw;text-align: center;">
                        <p style="font-size: .7vw;margin:0 0 3vw 1vw;color: #e48383">
                            ※送信内容の確認画面はありません。内容にお間違いがないかご確認の上、お送りください。</p>
                        <img src="@/assets/image/submitBackground.png" style="width: 20vw;margin: 0 7vw" alt="">
                        <p style="color: #fff;font-size: 1vw;position: absolute;top: 4.4vw;left: 15vw;">送信する</p>
                        <p style="color: #75655d;font-size: .5vw" @click="$router.back()">返回</p>
                    </div>

                </div>
            </el-col>
            <el-col :span="9">
                <div style="margin-left: -13vw;">
                    <div class="text" style="margin: 6vw 76vw;position: absolute;z-index: 2;left: 1.8vw">
                        <!-- <p style="writing-mode: vertical-rl;font-size: .5vw;font-weight: 600"> ご依頼・コラボ</p>
                            <p style="writing-mode: vertical-rl;">お問い合わせ</p>
                            <p style="writing-mode: vertical-rl;"> Contact</p> -->
                        <p style="position: absolute;width: 1vw;font-size: .6vw;font-weight: 600;top: 0">ご依頼・コラボ</p>
                        <p style="position: absolute;width: 1vw;left: 1.5vw;top: -.7vw">お問い合わせ</p>
                        <p style="position: absolute;writing-mode: vertical-rl;left: 2.1vw;top: -.7vw">Contact</p>
                    </div>
                    <div class="xinImg"
                        style="position: relative;z-index: 1;left: 14vw;top: 12vw;height: 12vw;width: 30vw;"
                        @mouseenter.stop="focusFun()" @mouseleave.stop="blurFun()">
                        <img src="@/assets/image/downXin.png" style="position: relative;z-index: 1;width: 19.4vw"
                            alt="">
                        <div class="shadw" style="position: relative;top: -11.6vw;z-index: 1;left: .4vw;width: 30vw">
                            <img class="shadwImage" src="@/assets/image/shadw.png" style="width: 18.5vw;" alt="">
                            <!-- <div class="testRou" style="backface-visibility: hidden;position: absolute;top: 0;left: 0;z-index:1;background: yellow">test111111111</div>
                            <div class="testRou" style="position: absolute;top:0;left:0;background:blue;">test222222222</div> -->
                        </div>
                        <img src="@/assets/image/upXin.png"
                            style="position: absolute;z-index: 1;left: .2vw;top: -.6vw;width: 19vw;" class="up" alt="">
                    </div>
                    <div class="tip" style="width: 30vw;position: relative;top: 5vw;left: 2vw;transform: scale(.9)">
                        <img src="@/assets/image/textBackground.png" style="width: 20vw;margin: 8vw 11vw;" alt="">
                        <img src="@/assets/image/emaill.png" style="position: absolute;top: 9.5vw;left: 14vw" alt="">
                        <div style="position: absolute;top: 9.5vw;left: 16vw;color: #75655d;font-size: .9vw">
                            NagisChoice@gmail.com</div>
                        <hr style="position: absolute;top: 11vw;left: 14vw;width: 14.4vw">
                        <p
                            style="position: absolute;top: 11.5vw;left: 14vw;color: #75655d;font-size: .7vw;line-height: 1.4vw">
                            48時間以内の返信をするように心がけています。<br>
                            <span
                                style="font-size: .55vw;font-weight: 600;color: #59a4a9">※土日祝日等、翌営業日以降の回答とさせていただきます。</span>
                        </p>
                    </div>
                </div>
            </el-col>


        </el-row>
        <index_bottom style="position: relative;top: 0vw" />
    </div>
    <!-- </div>
    </div> -->
</template>
<style scoped>
.scrollboxT {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-shrink: 0;
    transition: .9s cubic-bezier(0, -0.06, .04, .83)
}

.text p {
    /* position: ; */
    font-size: .9vw;
    color: #697472;
}

.viewboxT {
    position: fixed;
    top: 0;
    z-index: 6;
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.testBox {
    /* transform: rotateY(30deg) */
}

.delta {
    width: 5vw;
    height: 5vw;
    border-radius: 5vw;
    background: rgba(0, 0, 0, .3);
    /* box-shadow: 1vw 1vw 1vw black */
}

.up {
    /* transform:  1s ease */
    transition: .5s ease;
    transform-origin: top;
}

.xinImg:hover .testRou {
    animation: tst 1s forwards;
}

.shadwImage {
    transition: .5s ease;
    opacity: .1;
}

@keyframes tst {
    0% {
        transform: rotateY(0deg);
        filter: blur(0vw);
    }

    50% {
        transform: rotateY(90deg);
        filter: blur(.2vw);
    }

    100% {
        transform: rotateY(180deg);
        filter: blur(0vw);
    }
}

.testRou {
    height: 8vw;
}
</style>
<script>
// import anime from 'animejs/lib/anime.es.js';
import index_bottom from './index_bottom.vue'

export default {
    name: 'contactName',
    components: {
        index_bottom
    },
    props: {
    },
    data() {
        return {
        }
    },
    mounted() {
        const topNavBar = document.getElementsByClassName('topNavBar')[0]

        localStorage.setItem('copyScroll', '')
        localStorage.setItem('num', 0)
        document.body.style.height = '82vw'
        window.scrollTo({
            top: 0,
            behavior: 'auto' // 平滑滚动
        });
        setTimeout(() => {
            topNavBar.style.opacity = '1'
            topNavBar.style.transform = 'translateY(5vw)'
        }, 1000);
        // console.log(this.$router.currentRoute.value.path);
    },
    methods: {
        focusFun() {
            //shadwImage
            let upXin = document.getElementsByClassName('up')[0]
            let shadwImage = document.getElementsByClassName('shadwImage')[0]
            upXin.style.transform = 'rotateX(30deg)'
            shadwImage.style.height = '9.5vw'
        },
        blurFun() {
            let upXin = document.getElementsByClassName('up')[0]
            let shadwImage = document.getElementsByClassName('shadwImage')[0]
            upXin.style.transform = 'rotateX(0deg)'
            shadwImage.style.height = '8vw'
        }
    }
}
</script>
