<template>
    <div style="text-align: center;color:#6a615b;" class="goodsDetailBox" >
        <div class="goodsDetail">
            <el-row>
                <el-col :span="11">
                    <el-image style="width: 100%; height: 70%" :src="imgList[index]" fit="contain">
                    </el-image>
                    <el-image v-for="(item, index) in imgList" :key="item" fit="cover" @mouseover="onImg(index)"
                        :src="item" style="width: 15%;height: 10%;margin:1%;top: 2%;"></el-image>
                </el-col>
                <el-col :span='2'></el-col>
                <el-col :span="11" style="position: relative;">
                    <div style="font-weight: 600;text-align: left;margin-left: 3%;height: 7vw">
                        <p class="font1"
                            style="overflow-wrap: break-word;word-break: break-all;line-height: 1.5vw;font-size: 1vw;height: 2vw;">
                            {{ 'test测试/速度集合框架/ test the text /銀行振込/4245431246546' }}
                        </p>
                        <p class="price font2" style="font-size: .9vw;margin-top:1.5vw;height: 2vw;">
                            ￥{{ '18.00' }}
                            <span style="font-size: .6vw;color: #c3b8a0;">税込</span>
                        </p>
                        <p class="isPrice font4" style="color: red;margin-top: -1vw;height: 1vw;font-size: 1vw;">{{ 'SOLD OUT'
                            }}</p>
                    </div>
                    <div class="bottomBox font2"
                        style="position: absolute;bottom: 0;height: 20vw;left: 3%;text-align: left;font-weight: 600;line-height: 1.1vw">
                        <p style="font-size: .8vw;height: 2vw;">
                            ■ サイズ・対象ドール <br>MDD
                        </p>
                        <p style="font-size: .8vw;height: 2vw;">
                            ■ 仕様 <br>
                            正絹・アンティーク布・木馬リボン・貴和金具 <br>白蝶貝ボタン
                        </p>
                        <p style="margin-top: 3vw;height: .7vw;font-size: .7vw;color: #c1aa72;"><span
                                style="color:red">♡</span>いいね！をする：他５人
                        </p>
                        <button class="font2" style="width: 125%;height: 3vw;margin:.5vw 0;color: #c1aa72;background: #fdf9f5;"
                            @click="showCar()">カートに入れる</button>
                        <button class="font2" style="width: 125%;height: 3vw;background: #e1d0a7;color: #fff;">
                            <i>paypal</i>で購入
                        </button>
                        <p style="color: #afaeae;font-size: .6vw;width: 125%;margin-left: 100%;cursor: pointer;"
                            @click.stop="dialogClick()">別の支払い方法</p>
                    </div>
                </el-col>
            </el-row>
            <div class="allTip">
                <div class="tip1 font2" @click.stop="dialogShow(1)">
                    ▶ 発送について
                    <span class="tipSpan"
                        style="font-weight: 1200;font-size: .8vw;float: right;height: 1vw;">&gt;</span>
                </div>
                <div class="tip2 font2" @click.stop="dialogShow(2)">
                    ▶ ご利用について
                    <span class="tipSpan"
                        style="font-weight: 1200;font-size: .8vw;float: right;height: 1vw;">&gt;</span>
                </div>
            </div>
        </div>
        <avue-draggable :width="width" :height="height" :left="100" :top="200" id="draggable1" ref="draggable1"
            @focus.stop="handleFocus" @blur.stop="handleBlur">
            <div class="dialogBieDe font1">
                <!-- <img src="@/assets/image/noXinDialog.png" class="dialogBieDeImage" alt=""> -->
                <p style="color: #c1aa72;display: inline-block;margin: 5.5vw 0 .5vw;">
                    別の支払い方法
                </p>
                <span style="
            position: absolute;
            right: 1.5vw;top: 5.8vw;color: #c1aa72;font-size: 1vw;cursor: pointer;"
                    @click.stop="dialogClick('close')">x</span>
                <div class="hr" style="border-top: .1vw solid #c1aa72;width: 80%;margin-left: 9%;"></div>
                <div class="content">
                    銀行振込（ ゆうちょ銀行 | 三菱UFJ ）<br>

                    当店で商品の確保完了後、「銀行へのお支払いについて」<br>
                    という件名のメールを差し上げます（通常2営業日以内）。<br>

                    こちらのメールにて、お支払い方法や口座、期日など詳細を<br>
                    ご連絡を差し上げますので、ご確認の後お支払いをお願いいたします。<br>

                    ※銀行振込は、支払手数料360円（税込）+金融機関での振込手数料<br>
                    （各金融機関により異なる）がかかります。<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                </div>
            </div>
        </avue-draggable>
        <avue-draggable 
        :width="width" 
        :height="height" 
        :left="100" 
        :top="500" 
        id="draggable2" 
        ref="draggable2" 
        @focus.stop="handleFocus" @blur.stop="handleBlur">
        <div class="dialog font1">
            <div style="width: 100%;height: 100%;position: relative;top: 4vw;">
                <p style="color: #c1aa72;display: inline-block;margin: 1.5vw 0 .5vw;">
                    {{ dialogTitle }}
                </p>
                <span style="
            position: absolute;
            right: 1.5vw;top: 1.8vw;color: #c1aa72;font-size: 1vw;cursor: pointer;"
                    @click.stop="dialogShow('close')">x</span>
                <div class="hr" style="border-top: .1vw solid #c1aa72;width: 80%;margin-left: 9%;"></div>
                <div class="content">
                    銀行振込（ ゆうちょ銀行 | 三菱UFJ ）<br>

                    当店で商品の確保完了後、「銀行へのお支払いについて」<br>
                    という件名のメールを差し上げます（通常2営業日以内）。<br>

                    こちらのメールにて、お支払い方法や口座、期日など詳細を<br>
                    ご連絡を差し上げますので、ご確認の後お支払いをお願いいたします。<br>

                    ※銀行振込は、支払手数料360円（税込）+金融機関での振込手数料<br>
                    （各金融機関により異なる）がかかります。<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                    ※振込手数料 ご利用の金融機関によって異なります。（お客様ご負担）<br>
                </div>
            </div>
        </div>
        </avue-draggable>
        <div class="tip1Text font1" style="position: relative;width: 40vw;font-size: .7vw;text-align: left;top: 0;">
            <p class="tip1List" v-for="item in tipText" :key="item" style="height: 1vw;transition: .3s ease;">{{ item }}
            </p>
        </div>
    </div>
</template>
<script>
import contData from '@/components/shopTip.js'

export default {
    props: {
    },
    data() {
        return {
            index: 0,
            imgList: [
                'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
            ],
            tipText: contData,
            clickTipStatus: 0,
            dialogTitle: '',
            width: window.innerWidth * 25 / 100,
            height: window.innerWidth * 25 / 100,
        }
    },
    mounted() {
        // this.$refs.draggable1.setActive(false);
        const dom = document.getElementsByClassName('shopBox')[0]
        dom.scrollTo({
            top: 0,
            behavior: 'auto'
        })
    },
    methods: {
        onImg(index) {
            this.index = index
        },
        dialogClick(val) {
            const dom1 = document.getElementById('draggable1')
            const dom = document.getElementsByClassName('dialogBieDe')[0]
            if (val == 'close') {
                dom.style.transform = ' rotateY(90deg)'
                setTimeout(() => {
                    dom1.style.display = 'none'
                }, 500);
            } else {
                dom1.style.display = 'block'
                setTimeout(() => {
                    dom.style.transform = 'rotateY(0deg)'
                }, 1);
            }
            setTimeout(() => {
                console.log(dom.style.transform);
            }, 500);
        },
        dialogShow(index) {
            const dom = document.getElementsByClassName('dialog')[0]
            const dom1 = document.getElementById('draggable2')
            if (index == 'close') {
                dom.style.transform = 'rotateY(90deg)'
                setTimeout(() => {
                    dom1.style.display = 'none'
                }, 500);
            } else {
                dom1.style.display = 'block'
                this.dialogTitle = index == 1 ? '発送について' : 'ご購入時のお願い'
                setTimeout(() => {
                dom.style.transform = 'rotateY(0deg)'
                }, 1);
            }
        },
        showCar() {
            const dom = document.getElementById('carId')
            dom.style.display = 'block'
            setTimeout(() => {
                dom.style.opacity = '1'
            }, 1);
        },
        handleFocus() {
            // this.$refs.draggable1.setActive(false);
        },
        handleBlur() {
            // this.$refs.draggable1.setActive(false);
        },
    }
}
</script>
<style scoped>
.allTip {
    margin-top: 3vw;
    height: 7vw !important;
}
.tip1Text{
    height: 100vw;
}

.content {
    text-align: left;
    width: 80%;
    margin-left: 10%;
    line-height: 1.1vw;
    margin-top: 1vw;
    overflow: scroll;
    height: 15vw;
}

.content::-webkit-scrollbar {
    width: .2vw;
    /* 修改宽度 */
}

.dialogBieDe {
    width: 25vw;
    position: absolute;
    transform: rotateY(90deg);
    background: url('@/assets/image/xinDialog.png');
    background-size: 25vw 25vw;
    background-repeat: no-repeat;
    height: 25vw;
    /* left: 33vw; */
    font-size: .7vw;
    z-index: 99999;
    /* top: -34vw; */
    font-weight: 600;
    transition: .5s ease;
}

.dialog {
    width: 25vw;
    position: absolute;
    transform: rotateY(90deg);
    background: url('@/assets/image/xinDialog.png');
    background-size: 25vw 25vw;
    background-repeat: no-repeat;
    height: 25vw;
    /* left: 33vw; */
    font-size: .7vw;
    z-index: 9;
    font-weight: 600;
    transition: .5s ease;
}

button {
    border: .15vw solid #e1d0a7;
    border-radius: .3vw;
    font-size: 1vw;
    font-weight: 600;
}

.goodsDetail * {
    height: 30vw;
}

.bottomBox>* {
    height: 3vw;
}

.tip1 {
    width: 35vw;
    height: 2vw;
    border-bottom: .1vw solid #e1d0a7;
    border-top: .1vw solid #e1d0a7;
    transition: 1s ease;
    color: #e1d0a7;
    text-align: left;
    line-height: 2vw;
    font-size: .7vw;
    font-weight: 600;
    overflow: hidden;
}

.tipSpan {
    transition: 1s ease;
    transform-origin: 50% 50%;
}

.tip2 {

    width: 35vw;
    height: 2vw;
    border-bottom: .1vw solid #e1d0a7;
    transition: 1s ease;
    color: #e1d0a7;
    text-align: left;
    line-height: 2vw;
    font-size: .7vw;
    font-weight: 600;
    overflow: hidden;
}
</style>
<style lang="scss" scoped>
#draggable1,#draggable2 {
    background: transparent;
    border: none;
    display: none;
}

::v-deep .avue-draggable__line {
    // background: red;
    display: none;
}

::v-deep .avue-draggable__range {
    display: none;
}
</style>