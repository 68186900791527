<template>
  <div style="position: absolute;width: 90%;font-weight: 100;font-size: .5vw;margin-left: 5%">
    <div class="myPageTitle2">登録情報の変更</div>
    <div class="myPageTip">
      <p>
        ※登録情報を変更しても、ご注文の配達先は変わりません。
      </p>
    </div>
    <el-row class="fromBox" :gutter="20">
      <el-col :span="12">
        <input type="text" placeholder="2198512@qq.com" v-model="FormData.email">
        <input type="text" placeholder="姓名" v-model="FormData.name">
        <!-- <input type="text" placeholder="姓" v-model="FormData.fristNameT"> -->
        <input type="number" style="width: 20%;" v-model="FormData.firstCode">--
        <input type="number" style="width: 30%;" v-model="FormData.lastCode">
        <p>郵便番号)例:1234-455313</p>
        <input type="text" v-model="FormData.county">
        <p>番地・建物名・部屋番号</p>
        <input type="number" placeholder="431548654" v-model="FormData.phone">
        <p>電話番号</p>
        <p>例子:5465313245</p>
        <!-- <input type="password" style="background:rgb( 232,237,255);padding: .6vw 0;"  v-model="submitPassWord"> -->
      </el-col>
      <el-col :span="12">
        <!-- <input style="margin-top: 2.26vw;" type="text" placeholder="名" v-model="FormData.lastName">
        <input type="text" placeholder="名" v-model="FormData.lastNameT"> -->
        <select v-model="FormData.city">
          <option value="1">1</option>
          <option value="1">1</option>
        </select>
        <input type="text" placeholder="プライムア-パン四谷外死束604" v-model="FormData.location">
        <select style="width: 30%;" v-model="FormData.year">
          <option value="1">1999</option>
          <option value="1">1998</option>
        </select>/
        <select style="width: 20%;" v-model="FormData.moonth">
          <option value="1">12</option>
          <option value="1">11</option>
        </select>/
        <select style="width: 20%;" v-model="FormData.day">
          <option value="1">31</option>
          <option value="1">30</option>
        </select>
      </el-col>
    </el-row>
    <button style="background: #849d9b;
                    color: #fff;
                    border: none;
                    position: relative;
                    top: 4vw;
                    padding: .4vw 2vw;
                    border-radius: .3vw;
                    font-size: .7vw;
    " @click="$emit('clickItem',0)">確認する</button>
  </div>
</template>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.fromBox {
  margin-top: 1vw
}

.fromBox p {
  font-size: .5vw
}

input::placeholder {
  font-size: .5vw;
  color: #656d6c;
  /* opacity: .5; */
}

.myPageTip {
  margin: 2vw 0;
}

input,
select {
  width: 100%;
  border: none;
  border-radius: 0;
  padding-bottom: .5vw;
  border-bottom: .1vw solid rgba(101, 109, 108, .3);
  margin-top: .5vw;
  font-size: .6vw;
}

.myPageTitle2 {
  margin-top: 3vw;
  font-size: .8vw;
}
</style>
<script>

export default {
  props: {
  },
  data() {
    return {
      FormData: {
        email: '',
        fristName: '',
        fristNameT: '',
        lastNameT: '',
        lastName: "",
        lastCode: "",
        firstCode: "",
        city: "",
        county: "",
        location: ""
      },
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
