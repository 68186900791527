<template>
    <div style="position: relative;color: #4b4b4b;font-size: .5vw; width: 85%;
    margin-left: 7.5%;
    ">
        <div>
            <div class="fivePageTitle" style="font-size: .8vw;margin-top: 3vw;color:  #89b1ad;">
                お問い合わせ履歴
            </div>
            <div style="overflow: scroll;height: 33vw;">
                <div style="margin-top: 2vw;">
                    <div class="emailItem" v-for="(item, index) in mail" :key="item">
                        <p>お問い合わせ日時<span>{{ item.time }}</span></p>
                        <p>タイトル <span>{{ item.title }}</span></p>
                        <p style="display: inline-block;position: absolute;margin: 0">お問い合わせ内容</p>
                        <span style="display: inline-block;width:70%;
                        position: relative;
                        word-wrap: break-word;
                        left: 30%;
                        ">
                            {{ item.content }}
                        </span>
                        <div v-if="item.reply !== '' && item.isMore" style="width:95%;
                        border-radius: .2vw;border: .1vw solid rgba(101, 109, 108, .3);
                        position: relative;
                        min-height: 2vw;
                        word-wrap: break-word;
                        padding:0 1%;
                        margin-left: 2.5%;
                        top: 1.5vw;
                        ">
                            <p style="text-align: left;">
                                {{ item.reply }}
                            </p>
                            <p style="position: relative;left: 100%;transform: translateX(-14%)">nagis's Choice</p>
                        </div>
                        <div @click="moreFun(index)" class="fiveMore" style="width: 100%;height: 1vw;text-align: center;position: absolute;bottom: 0;
    border-bottom: .1vw solid rgba(101, 109, 108, .3);
                        ">
                            {{ item.isMore == false ? 'もっと見るv' : '' }}
                        </div>
                    </div>
                </div>
                <button style="
                        font-size: .5vw;
                        border:none;
                        border-radius: .2vw;
                        padding: .3vw 2vw;
                        color: #fff;
                        background: #849d9b;
                        position: relative;
                        top: 3vw;
                    " @click="$emit('clickItem', 0)">マイページへ</button>
            </div>
        </div>
    </div>
</template>
<style scoped>
.emailItem {
    position: relative;
    transition: 1s ease;
    padding-bottom: 3vw;
}

.emailItem>p>span {
    position: absolute;
    left: 30%;
}
</style>
<script>

export default {
    props: {
    },
    data() {
        return {
            mail: [
                {
                    title: '期望出新',
                    time: '2024/02/09 09:05:52',
                    content: 'ahsdhjgjsgblkajsldadhshfdggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggsbasjkdbhjkgajsgdjaksgdhajgsdjhagsdajhsgdajsvbdjhasvdbnvsdjhagsjdhasbdhbvnsbvdjhasdasdba',
                    reply: "",
                    isMore: false,
                },
                {
                    title: '期望出新2',
                    time: '2024/02/09 09:05:12',
                    content: 'aaddddddddddddddddddddasdasdasdasd',
                    reply: "好的",
                    isMore: false,
                },
            ]
        }
    },
    mounted() {

    },
    methods: {
        moreFun(index) {
            const dom = document.getElementsByClassName('fiveMore')
            Object.values(dom).map((item, indexT) => {
                console.log(indexT);
                if (index == indexT) {
                    this.mail[index].isMore = true
                } else {
                    this.mail[indexT].isMore = false
                }
            }
            )
        }
    }
}
</script>