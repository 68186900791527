<template>
    <div>
        <div class="video" id="video" style="position: fixed;z-index: 3;"
            v-show="$router.currentRoute.fullPath == '/index_twice'">
            <video style="width: 100vw;" autoplay loop muted playsinline>
                <source src="/video/bg.MP4">
            </video>
        </div>
        <div :class="`logo_NA ${isShow ? 'isShow' : ''}`" v-show="$router.currentRoute.fullPath == '/index_twice'">
            <img class="bigLogo" src="@/assets/image/NA.png" alt="">
        </div>
        <img src="@/assets/image/topLogo.png"
            style="position: fixed;z-index: 1000;left: 45vw;width: 5vw;opacity: 0;transform: translateY(3vw);transition: 1s ease;top: .5vw"
            width="100%" alt="" class="topTab">
        <div class="topNavBar font2" @mouseleave="ifTopNavBar(false)"
            style="width: 100vw;height: 3vw;background:rgba(117,131,130,.3);z-index: 999;position: fixed;top: -5vw;opacity: 0;">
            <div style="width: 100vw;
                height: .5vw;
                position: absolute;
                top: 3vw;
                    background: rgba(117, 131, 130, 0.3);
                    border-top: .1vw solid rgba(255, 255, 255, .3);"></div>

            <div class="leftButton" style="top: 2vw;position: relative;left: 4vw;">
                <p class="changeColor" @click="routerFun('index_twice')">Home
                </p>
                <!-- mouseenter移入 ，mouseleave移出 -->
                <div style="position: relative;" class="changeColor">
                    <span class="aboutText">About</span>
                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="1vw"
                        style="top:.3vw ;position: relative;" height="1vw">
                        <path
                            d="M904 332c0-8.189-3.124-16.379-9.372-22.628-12.497-12.496-32.759-12.496-45.256 0L512 646.745 174.628 309.372c-12.497-12.496-32.758-12.496-45.255 0-12.497 12.498-12.497 32.758 0 45.256l360 360c12.497 12.496 32.758 12.496 45.255 0l360-360C900.876 348.379 904 340.189 904 332z"
                            fill="#ffffff"></path>
                    </svg>
                    <div class="aboutOption font2" @mouseenter="showOption('about')" @mouseleave="closeOption('about')">
                        <div class="backgroundImg">
                            <img class="aboutImage" src="@/assets/image/navBarBackground.png"
                                style="width: 3vw;height: 8vw;position: absolute;top: 3vw;left: 0;opacity: .5;" />
                            <p class="aboutOption1" @click="routerFun('about_nagi')">
                                ・Nagi’s Choice
                            </p>
                            <div class="aboutHr" style="top: 1.5vw;"></div>
                            <p class="aboutOption1" @click="routerFun('about_circus')">
                                ・CIRCUS DOLL
                            </p>
                            <div class="aboutHr" style="top: 1.5vw;"></div>
                            <p class="aboutOption1" @click="routerFun('about_agreement')">
                                ・よくある質問
                            </p>
                        </div>
                    </div>
                </div>
                <div class="changeColor ">
                    <span class="shopText">Shop</span>
                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="1vw"
                        style="top:.3vw ;position: relative;" height="1vw">
                        <path
                            d="M904 332c0-8.189-3.124-16.379-9.372-22.628-12.497-12.496-32.759-12.496-45.256 0L512 646.745 174.628 309.372c-12.497-12.496-32.758-12.496-45.255 0-12.497 12.498-12.497 32.758 0 45.256l360 360c12.497 12.496 32.758 12.496 45.255 0l360-360C900.876 348.379 904 340.189 904 332z"
                            fill="#ffffff"></path>
                    </svg>
                    <div class="shopOption font2" @mouseenter="showOption('shop')" @mouseleave="closeOption('shop')">
                        <div class="backgroundImg">
                            <img class="shopImage" src="@/assets/image/navBarBackground.png"
                                style="width: 3vw;height: 8vw;position: absolute;top: 3vw;left: 0;opacity: .5;" />
                            <div class="shopOption1" @click="routerFun('circusdoll')">
                                <img style="width: 2.1vw;" src="@/assets/image/barContentImageTop.png" alt="">
                                <p style="display: inline-block">Doll Products <br>ドール関連製品 </p>
                            </div>
                            <div class="shopHr" style="top: 1.2vw;"></div>
                            <div class="shopOption1">
                                <el-tooltip content="coming soon..." placement="right" effect="light">
                                    <p style="display: inline-block">・Nagi’s Choice<br>オリジナル製品</p>
                                    <img style="width: 2.2vw;" src="@/assets/image/barContentImageBottom.png" alt="">
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="changeColor" @click="routerFun('contact')">Contact
                </p>
                <p class="changeColor" @click="routerFun('galary_index')">Galary
                </p>
            </div>
            <div class="rightIcon" style="position: relative;float: right;right: 1vw;top: -1.4vw">
                <img src="@/assets/image/my.png" style="margin-right: 2vw;" alt="">
                <img src="@/assets/image/shop.png" style="margin-right: 2vw;" alt="">
                <div class="changeColor upSelect "
                    style="font-family: fangsong;font-size: 1vw;top:-.2vw;position: relative;margin-left: 0;">
                    <span class="langText">EN</span>
                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="1vw"
                        style="top: .1vw;position: relative;" height="1vw">
                        <path
                            d="M904 332c0-8.189-3.124-16.379-9.372-22.628-12.497-12.496-32.759-12.496-45.256 0L512 646.745 174.628 309.372c-12.497-12.496-32.758-12.496-45.255 0-12.497 12.498-12.497 32.758 0 45.256l360 360c12.497 12.496 32.758 12.496 45.255 0l360-360C900.876 348.379 904 340.189 904 332z"
                            fill="#ffffff"></path>
                    </svg>
                    <div class="langOption font2"
                        style="transform: rotateY(180deg); font-size: .7vw;transform-origin: left;margin-left: 2vw"
                        @mouseenter="showOption('lang')" @mouseleave="closeOption('lang')">
                        <div class="backgroundImg" style="transform: rotateY(180deg);">
                            <img class="langImage" src="@/assets/image/navBarBackground.png"
                                style="width: 3vw;height: 8vw;position: absolute;top: .2vw;left: 0;opacity: .5;" />
                            <p class="langOption1">
                                日语
                            </p>
                            <div class="langHr" style="top: 1.5vw;"></div>
                            <p class="langOption1">
                                中文
                            </p>
                            <div class="langHr" style="top: 1.5vw;"></div>
                            <p class="langOption1">
                                English
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="viewbox">
            <div class="scrollbox ">
                <div style="width: 100vw;height:356vw;overflow: hidden">
                    <index_first v-if="$router.currentRoute.fullPath == '/index_twice'" style="position: absolute;" />
                    <router-view></router-view>
                    <index_auto v-show="$router.currentRoute.fullPath == '/index_twice'" />
                </div>
            </div>
        </div>
        <div id="menban" @click="closeShop()">
            <div id="shopDetail" @click.stop="console.log('')">
                <img src="@/assets/image/shopDetailLog.png" alt="" class="shopDetailLog">
                <div class="shopBox" style="width: 100%;height: 90vh;overflow: scroll">
                    <ComponentsDetail style="width: 35vw;margin-left: 3vw;height: 100vh" />
                </div>
            </div>
        </div>
        <addCar />
        <canvas id="noise"></canvas>
    </div>
</template>
<script>
import index_first from "./index_first.vue"
import index_auto from "./index_auto.vue"
import anime from 'animejs/lib/anime.es.js';
import ComponentsDetail from "./shop/componentsDetail.vue";
import addCar from "@/components/add_car.vue";
// import axios from "axios";
// import { animeTranslateY } from "@/components/anime";

export default {
    name: 'index_nagi',
    props: {
    },
    components: {
        index_first, index_auto, ComponentsDetail, addCar
    },
    data() {
        return {
            isShow: false,
            scrollOf: 0,
            topFirst: "Doll Products",//Doll Products
            topTwo: "ドール関連製品",//ドール関連製品
            bottomFirst: "Ngi’s Choice",//Ngi’s Choice
            bottomTwo: "オリジナル製品",//オリジナル製品
            muenId: {
                'about': '',
                'lang': '',
                'shop': '',
            },
            height: '',
            lastAnmiteStatus: false,
            deltaY: 0,
        }
    },
    watch: {
        scrollOf(val) {
            const transform1 = document.getElementsByClassName('transform1')[0]
            const lastAnmite = document.getElementsByClassName('lastAnmite')[0]
            const transform2 = document.getElementsByClassName('transform2')[0]
            const showImgArr = document.getElementsByClassName('showImgArr')[0]
            const shuText = document.getElementsByClassName('shuText')[0]
            const text3 = document.getElementsByClassName('text3')[0]
            const transform3 = document.getElementById('transform3')
            const title1 = document.getElementsByClassName('title1')[0]
            const transform4 = document.getElementsByClassName('transform4T')[0]
            const domArr = document.getElementsByClassName('transform4')
            const titleText = document.getElementsByClassName('titleText')[0]
            if (this.$router.currentRoute.fullPath == '/index_twice') {
                if (this.getElementDistanceToBottom(transform4) > -200) {
                    Object.values(domArr).map((item) => {
                        item.style.transform = 'none'
                    })
                }
                if (this.getElementDistanceToBottom(titleText) > 600) {
                    this.$bus.$emit('lineShow')
                }
                if (lastAnmite.getBoundingClientRect().top > 0 && this.deltaY < 0) {
                    this.lastAnmiteStatus = false
                }
                if (lastAnmite.getBoundingClientRect().top < 500 && this.deltaY > 0) {
                    this.lastAnmiteStatus = true
                }

                if (this.getElementDistanceToBottom(title1) > 0) {
                    title1.style.opacity = 1
                }
                if (this.getElementDistanceToBottom(text3) > 0) {
                    this.$bus.$emit('text3')
                }
                if (this.getElementDistanceToBottom(shuText) < 0 && this.deltaY < 0) {
                    this.$bus.$emit('text1', true)
                }
                if (this.getElementDistanceToBottom(shuText) > 0) {
                    this.$bus.$emit('text1', false)
                }
                if (this.getElementDistanceToBottom(showImgArr) > 0) {
                    const dom2 = showImgArr
                    dom2.style.opacity = '1'
                    dom2.style.filter = 'blur(0)'
                }
                if (this.getElementDistanceToBottom(transform2) > 0) {
                    const dom = transform2
                    const dom1 = document.getElementsByClassName('transform2')[1]
                    dom.style.transform = dom1.style.transform = 'translateY(-' + (val - 1500) / 300 + 'vw)'
                }
                if (this.getElementDistanceToBottom(transform3) > 0) {
                    this.$bus.$emit('show1Fun', false)
                    this.$bus.$emit('path1')
                }
                if (this.getElementDistanceToBottom(transform1) > 0) {
                    const dom = document.getElementsByClassName('transform1')[0]
                    dom.style.transform = "none"
                    dom.style.opacity = '1'
                    dom.style.filter = 'blur(0)'
                }
            }
        },
        lastAnmiteStatus(val) {
            const blackMoban = document.getElementsByClassName('blackMoban')[0]
            // const transform4 = document.getElementsByClassName('transform4')
            const titleTextT = document.getElementsByClassName('titleTextT')[0]
            if (val == true) {
                blackMoban.style.display = 'block'
                titleTextT.style.transform = 'scale(.9) translateY(.5vw)'
                setTimeout(() => {
                    blackMoban.style.opacity = '.5'
                }, 1);
            } else {
                blackMoban.style.opacity = '0'
                titleTextT.style.transform = 'scale(1)'
                setTimeout(() => {
                    blackMoban.style.display = 'none'
                }, 1000);
            }
        }
    },
    mounted() {

        // this.generateNoise()
        setInterval(() => {
            this.generateNoise()
        }, 100);
        this.$bus.$on('showNarBar', e => {
            this.ifTopNavBar(e)
        })
        // //test
        // axios({
        //     method: 'post',
        //     url: 'https://www.nagischoice.com/api/goods/add',
        //     // params:{'nickName':'ywb','password':'123456'},
        //     // responseType: 'blob',
        //     // headers: { 'Authorization': 'Bearer ' + getToken() }
        // }).then(res => {
        //     console.log(res)
        // })
        // //end
        let scrollbox = document.getElementsByClassName("scrollbox")[0];
        let video = document.getElementById('video')
        let bigLogo = document.getElementsByClassName('bigLogo')[0]
        let topTab = document.getElementsByClassName('topTab')[0]
        let showList = document.getElementsByClassName('showList')[0]

        let scrollDom = document.getElementsByClassName('scrollDom')[0]
        let _this = this
        var i = 0
        let copyScroll = ''
        let heightAdd
        // const testDom = document.getElementsByClassName('scrollDom')[0]
   
        setTimeout(() => {
            heightAdd = Number(document.body.style.height.slice(0, document.body.style.height.indexOf('px')))
        }, 6000);

        function scroll() {
            console.log('scrolling')
            _this.deltaY = _this.scrollOf > scrollY ? -1 : 1
            // function isElementHalfInViewportCenter(element) {
            //     const rect = element.getBoundingClientRect();
            //     const viewportHeight = window.innerHeight;
            //     const viewportCenter = viewportHeight / 2;

            //     // 计算元素上边缘距离视口中心的距离
            //     const distanceToTop = Math.abs(rect.top + rect.height / 2 - viewportCenter);

            //     // 判断距离是否小于元素高度的一半
            //     return distanceToTop <= rect.height / 2;
            // }
            // function isElementBottomAtViewportBottom(element) {
            //     const rect = element.getBoundingClientRect();
            //     const viewportHeight = window.innerHeight;

            //     const elementBottom = rect.bottom;
              
            //     return elementBottom <= viewportHeight;
            // }

            if (_this.$router.currentRoute.fullPath == '/about_circus') {
                _this.$bus.$emit('aboutCircusScroll', scrollY)

                // const showListcopy = document.querySelector('.showListcopy')
                // const scroll_dom = showListcopy.querySelector('.copy_scroll_dom')
                // const scroll_box = showListcopy.querySelector('.copy_scroll_box')
                // const scroll_width = scroll_dom.offsetWidth

                // let scrollbox = document.getElementsByClassName("scrollbox")[0];
                // if (isElementHalfInViewportCenter(showListcopy) || scroll_dom.getAttribute('data-prevscrolly')) {

                //     if (!scroll_dom.getAttribute('data-scrolly')) {
                //         scroll_dom.setAttribute('data-scrolly', scrollbox.style.transform.replace(/[^\d.]/g, ''))
                //         h = Number(document.body.style.height)
                //     }

                //     const prev_scrollY = Number(scroll_dom.getAttribute('data-scrolly'))
                //     if (scrollY < prev_scrollY) {
                //         scrollbox.style.transform = `translateY(-${scrollY}px)`
                //         scroll_dom.removeAttribute('data-scrolly')
                //         scroll_dom.removeAttribute('data-prevscrolly')
                        
                //         return
                //     }
                //     const x = prev_scrollY - scrollY;
                //     const mx = scroll_box.offsetWidth - scroll_width
                //     let transformX = Math.max(x, mx);
                //     scroll_dom.style.transform = `translateX(${transformX}px)`

                //     if (transformX === mx) {
                //         if (!scroll_dom.getAttribute('data-prevscrolly')) {
                //             scroll_dom.setAttribute('data-prevscrolly', scrollY)

                //         }
                //         let pev = Number(scroll_dom.getAttribute('data-prevscrolly'))
                //         if (isElementBottomAtViewportBottom(document.querySelector('.bdx'))){
                //             return
                //         }
                //         scrollbox.style.transform = `translateY(-${prev_scrollY + (scrollY - pev)}px)`
                //         return
                //     }
                //     document.body.style.height = h + transformX + 'px'
                //     scrollbox.style.transform = "" + scrollbox.style.transform
                //     return
                // }
            }
            if (_this.$router.currentRoute.fullPath == '/circusdoll') {
                _this.$bus.$emit('circusdollScroll', scrollY)
            }
            if (_this.$router.currentRoute.fullPath == '/galary_index') {
                return
            }
            if (scrollY < 1000) {
                copyScroll = ''
                i = 0
            }

            if (_this.getElementDistanceToBottom(showList) > 0 && scrollY - copyScroll > 0 && showList.getBoundingClientRect().top !== 0) {

                if (i == 0) {
                    copyScroll = scrollY
                }
                if (scrollY - copyScroll > (22 * 7 - 50) * window.innerWidth / 100) {
                    _this.lastAnmiteStatus = false
                    _this.$bus.$emit('text4')
                    scrollbox.style.transform = `translateY(${-(scrollY - (22 * 7 - 50) * window.innerWidth / 100)}px)`
                    return
                }
                else {

                    scrollDom.style.transform = `translateX(${-(scrollY - copyScroll)}px)`
                    document.body.style.height = heightAdd + scrollY - copyScroll + 'px'
                    _this.$bus.$emit('path2')
                    document
                }
                i++
                if (i == 1) localStorage.setItem('num', 1)
            } else {

                scrollbox.style.transform = `translateY(${-(scrollY)}px)`
            }

            video.style.transform = `translateY(${-scrollY * .1}px)`
            if (scrollY < 540) {
                bigLogo.style.opacity = 1 - scrollY / 540
                bigLogo.style.display = 'block'
                bigLogo.style.filter = `blur(${scrollY / 540}vw)`
                topTab.style.transform = 'translateY(-3vw)'
                topTab.style.opacity = '0'

            } else {
                bigLogo.style.display = 'none'
                topTab.style.transition = '1s ease'
                topTab.style.opacity = '1'
                topTab.style.transform = 'translateY(0)'
            }
            if (scrollY > _this.scrollOf) {
                _this.ifTopNavBar(true)
            } else {
                _this.ifTopNavBar(false)
            }

            _this.scrollOf = scrollY
        }

        window.addEventListener("scroll", scroll);
        setTimeout(() => {
            this.isShow = true
        }, 4000);
    },
    methods: {
        getElementDistanceToBottom(element) {
            var rect = element.getBoundingClientRect();
            var height = window.innerHeight || document.documentElement.clientHeight;
            var distance = height - rect.bottom;
            return distance;
        },
        animeAllFun(time, arr, top, autoplay) {
            anime({
                targets: arr,
                top: top,
                duration: time,
                autoplay: autoplay
            })
        },
        routerFun(val) {
            this.$router.push({ path: '/' + val })
        },
        // changeSelect(val) {
        //     this.routerFun(val)
        //     const nav = document.getElementsByClassName('topNavBar')[0]
        //     const dom = document.getElementsByClassName('option')
        //     Object.values(dom).map((item) => {
        //         item.style.background = '#cdcac4'
        //     })
        //     nav.style.background = 'rgba(116, 106, 92, .3)'
        // },
        // testFun() {
        //     const nav = document.getElementsByClassName('topNavBar')[0]
        //     const dom = document.getElementsByClassName('option')
        //     Object.values(dom).map((item) => {
        //         item.style.background = '#dad6ca'
        //         // item.style. = '#cdcac4'
        //     })
        //     nav.style.background = 'rgba(117,131,130,.3)'
        // },
        ifTopNavBar(val) {
            const topNavBar = document.getElementsByClassName('topNavBar')[0]
            if (val == true) {
                topNavBar.style.transform = 'translateY(5vw)'
                topNavBar.style.opacity = '1'
            } else {
                topNavBar.style.transform = 'translateY(-5vw)'
                topNavBar.style.opacity = '0'
            }
        },
        recordScroll() {
            return 'shabi'
        },
        closeShop() {
            this.$bus.$emit('closeShop')
        },
        mouseenterMeun(val) {
            clearTimeout(this.muenId[val])
            const dom = document.getElementsByClassName(val + 'Meun')[0]
            const text = document.getElementsByClassName(val + 'OptionText')
            let shopTop = ''
            let shopBottom = ''
            dom.style.transform = 'rotateY(0deg)'
            if (val !== 'shop') {
                const dom1 = document.getElementsByClassName(val + 'OptionText')
                const data = Object.values(dom1)
                let i = 0
                let setId = setInterval(() => {
                    data[i].style.opacity = '1'
                    data[i].style.filter = 'blur(0)'
                    i++
                    if (i >= data.length) {
                        clearInterval(setId)
                    }
                }, 400)
            } else {
                shopTop = document.getElementsByClassName('top')[0]
                shopBottom = document.getElementsByClassName('bottom')[0]
                shopTop.style.opacity = '1'
                shopTop.style.filter = 'blur(0)'
                setTimeout(() => {
                    shopBottom.style.opacity = '1'
                    shopBottom.style.filter = 'blur(0)'
                }, 40);
            }
            this.muenId[val] = setTimeout(() => {
                if (shopTop !== '') {
                    shopTop.style.opacity = shopBottom.style.opacity = '0'
                    shopTop.style.filter = shopBottom.style.opacity = 'blur(.3vw)'
                }
                dom.style.transform = 'rotateY(90deg)'
                Object.values(text).map((item) => {
                    item.style.opacity = '0'
                    item.style.filter = 'blur(.3vw)'
                })
            }, 5000);
        },
        showOption(val) {
            const dom = document.getElementsByClassName(val + 'Option')[0]
            const dom2 = document.getElementsByClassName(val + 'Image')[0]
            const dom3 = document.getElementsByClassName(val + 'Option1')
            const dom4 = document.getElementsByClassName(val + 'Hr')
            const text = document.getElementsByClassName(val + 'Text')[0]
            text.style.color = `${this.$router.currentRoute.fullPath == '/shop_index' ? 'red' : '#719595'}`
            dom.style.height = '11vw'
            setTimeout(() => {
                dom2.style.transition = '.5s ease'
                dom2.style.width = "15vw"
                if (val == 'lang') {
                    dom.style.right = dom2.style.width
                }
            }, 100);
            setTimeout(() => {
                dom.style.width = '15vw'
            }, 200);
            setTimeout(() => {
                Object.values(dom3).map((item) => {
                    item.style.opacity = '1'
                    item.style.transform = 'translateX(0vw)'
                })
                Object.values(dom4).map((item) => {
                    item.style.opacity = '.5'
                })
            }, 300);
        },
        closeOption(val) {
            const dom = document.getElementsByClassName(val + 'Option')[0]
            const dom2 = document.getElementsByClassName(val + 'Image')[0]
            const dom3 = document.getElementsByClassName(val + 'Option1')
            const dom4 = document.getElementsByClassName(val + 'Hr')
            const text = document.getElementsByClassName(val + 'Text')[0]
            text.style.color = '#fff'
            dom.style.transition = dom2.style.transition = '.5s ease'
            dom.style.height = '2vw'
            dom.style.width = dom2.style.width = '3vw'
            Object.values(dom3).map((item) => {
                item.style.opacity = '0'
                item.style.transform = 'translateX(-8vw)'
            })
            Object.values(dom4).map((item) => {
                item.style.option = '0'
            })
            if (val == 'lang') {
                dom.style.left = '0vw'
            }
        },
        generateNoise() {

            const canvas = document.getElementById('noise');
            const ctx = canvas.getContext('2d');
            // 调整 canvas 大小
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            const imageData = ctx.createImageData(canvas.width, canvas.height);
            const data = imageData.data;

            for (let i = 0; i < data.length; i += 4) {
                // 随机生成 RGB 颜色值，值在 0-255 之间
                const value = Math.random() * 255;
                data[i] = value;     // R
                data[i + 1] = value; // G
                data[i + 2] = value; // B
                data[i + 3] = 20;   // A (不透明)
            }

            ctx.putImageData(imageData, 0, 0);
        }
    }
}
</script>
<style>
@font-face {
    font-family: 'myFont1';
    src: url('@/assets/font/font1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'myFont2';
    src: url('@/assets/font/font2.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'myFont3';
    src: url('@/assets/font/font3.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'myFont4';
    src: url('@/assets/font/font4.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

*::-webkit-scrollbar {
    width: .1vw;
}

/* .blackMoban{
    width: 100vw;
    height: 100vh;
    z-index: 14;
    position: fixed;
    display: none;
    background-color: rgb(0, 0, 0);
    opacity: 0;
} */
.font1 {
    font-family: myFont1;
}

.font2 {
    font-family: myFont2;
}

.font3 {
    font-family: myFont3;
}

.font4 {
    font-family: myFont4;
}

* {
    cursor: pointer;
}

/* #menban::-webkit-scrollbar {
    width: .2vw;
} */


/* .shopBox::-webkit-scrollbar {
    width: .2vw;
} */

#menban {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999999999;
    display: none;
    opacity: 0;
    transition: 1s ease;
    /* overflow: scroll; */
}

::selection {
    background: #a1a9a3;
    /* 粉红色的底色 */
    color: #fff;
    /* 文字的颜色 */
}
</style>
<style scoped>
#noise {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.backgroundImg {
    width: 15vw;
    height: 8vw;
    margin-top: 3vw;
}

.aboutHr,
.langHr,
.shopHr {
    width: 10vw;
    border-top: .1vw solid #fff;
    margin: 0 auto;
    opacity: 0;
    position: relative;
    transition: .5s ease;
}

.aboutOption1,
.langOption1,
.shopOption1 {
    position: relative;
    top: 1.5vw;
    text-align: center;
    width: 15vw;
    left: 0;
    transform: translateX(-8vw);
    /* transition: .5s cubic-bezier(.1, 1, .62, 1.34); */
    transition: .5s ease;
    opacity: 0;
}

.shopOption1>img {
    margin: -.2vw 1vw
}

.aboutOption1:hover {
    color: #d97e71;
}

.shopOption1:hover {
    color: #d97e71;
}

.langOption1:hover {
    color: #d97e71;
}

.aboutOption,
.langOption,
.shopOption {
    /* width: ; */
    transition: .1s ease;
    position: absolute;
    overflow: hidden;
    width: 3vw;
    height: 2vw;
    /* background: red; */
    left: 0;
    cursor: pointer;
    top: 0
}



/* .shopText:hover {
    color: red;
} */

/* .shopText {
    position: relative;
    top: .5vw;
} */

.shopOption>div>img {
    width: 3vw;
    position: relative;
    top: 1vw;
}

.shopDetailLog {
    width: 3vw;
    transition: 1s ease;
    margin-left: 18.5vw;
}

#shopDetail {
    width: 45vw;
    height: 100vh;
    transition: 1s ease;
    background: #fdf9f5;
    position: absolute;
    right: 0;
}

.langMeun {
    transform: rotateY(90deg);
    transform-origin: top;
    transition: all .5s ease;
    z-index: 999,
}

.langMeun .option {
    left: -8vw;
    width: 8vw;
    border-radius: .5vw;
    text-align: center;
}

.langMeun .option hr {
    width: 5vw;
    height: .07vw;
    border: none;
    background: #fff;
}

.aboutMeun {
    transform: rotateY(90deg);
    transform-origin: top;
    transition: all .5s ease;
    z-index: 999,
}

.aboutMeun .option {
    width: 15vw;
    /* text-align: left; */
    /* padding-left: 10vw; */
}

.aboutMeun .option hr {
    width: 13vw;
    height: .07vw;
    border: none;
    background: #fff;
}

.aboutMeun .option div {
    padding-left: 3vw;
}

/* 商店 */
.shopMeun .imgBackground {
    width: 20vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.shopMeun {
    left: 14vw;
    transform: rotateY(90deg);
    transition: all .5s ease;
    transform-origin: top;
}

.content {
    z-index: 1;
    position: relative;
    /* background: red; */
    top: 0;
}

.topImg {
    width: 3vw;
    position: relative;
    margin-right: 2vw;
    opacity: 0;
    transition: all 1s ease
}

.content .top {
    /* margin-top: 1.5vw; */
    position: relative;
    /* z-index: 100000 */
}

.topImg+div {
    top: -.5vw;
    position: relative;
    color: #fff;
    display: inline-block;
    font-size: .7vw;
    width: 5vw;
}

.bottomImg {
    margin-left: 1vw;
    width: 4vw;
    opacity: 0;
    transition: all 1s ease
}

/* .top:hover .shopTop {
    color: red;
} */

.shopBottom {
    color: #fff;
    width: 5vw;
}

.bottom:hover .shopBottom {
    color: red;
}

/* 公共 */
.option {
    position: absolute;
    top: 1vw;
    color: #000;
    background: rgb(224, 219, 207);
    height: 6vw;
    border: .1vw solid #fff;
    padding: 1vw 0;
    transition: all 1s ease;
}

.option div {
    /* margin-bottom:0vw; 
    margin-left: 1.8vw; */
    /* color: #fff; */
    position: relative;
    opacity: 0;
    transition: 1s ease;
    filter: blur(.3vw);
}



.option>div:hover {
    color: #fe4c39;
}

.leftButton>p,
.changeColor {
    display: inline-block;
    margin: 0 1vw;
    position: relative;
    top: -1vw;
    color: #fff;
    font-size: .7vw;
}

.topNavBar {
    transition: all 1s ease;
}

.rightIcon {
    top: -3vw;
    position: relative;

}

.rightIcon>img {
    width: 1vw;
    margin-top: 1vw;
}



#video {
    transition: 0.15s ease;
}

.scrollbox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-shrink: 0;
    top: 0;
    transition: .9s cubic-bezier(0, -0.06, .04, .83);
}

.viewbox {
    position: fixed;
    top: 0;
    z-index: 6;
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.viewbox::-webkit-scrollbar {
    display: none;
}

.logo_NA img {
    width: 20vw;
    /* margin: 0 auto */
}

.isShow {
    animation: logoShow 2s forwards;
}

.logo_NA {
    position: fixed;
    z-index: 10;
    filter: blur(2vw);
    top: 8vw;
    left: 40vw;
    width: 100vw;
    opacity: 0;
}

@keyframes logoShow {
    0% {
        filter: blur(2vw);
        opacity: 0;
    }

    50% {
        filter: blur(1vw);
    }

    100% {
        filter: blur(0vw);
        opacity: 1;
    }
}
</style>
