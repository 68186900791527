<template>
  <div style="position: absolute;width: 100%;">
    <p class="titleMy">
      マイページ
    </p>
    <div class="meunList">
      <p style="font-style:italic;font-size: .8vw">◯◯ 様</p>
      <p 
        
        v-for="(item,index) in menuList" :key="item" @click="$emit('clickItem', index+1 )">
        {{ item }}</p>
    </div>
    <button class="exitButton">サインアウト</button>
  </div>
</template>
<style scoped>
.exitButton {
  background: #849d9b;
  color: #fff;
  border: none;
  position: relative;
  top: 4vw;
  margin-left: 4vw;
  padding: .3vw 2vw;
  border-radius: .3vw;
}

.titleMy {
  margin: 4vw;
}

.meunList {
  margin: -2vw 4vw;
}

.meunList>p {
  border-bottom: .1vw solid rgba(101, 109, 108, .3);
  padding: .5vw 0;
  margin: 0;
  position: relative;
  z-index: 3;
}
</style>
<script>

export default {
  props: {
  },
  data() {
    return {
      menuList: [
        '登録情報',
        'ご注文履歴',
        'いいね履歴',
        'お問い合わせ履歴'
      ]
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
