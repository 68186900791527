import Vue from 'vue'
import App from './App.vue'
import router from './router/index'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import Avue from '@smallwei/avue';
// import '@smallwei/avue/lib/index.css';


Vue.prototype.$bus = new Vue()
Vue.use(ElementUI);
// Vue.use(Avue);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
