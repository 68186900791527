<template>
  <div style="background:#fdfaec;height: 100vh">
    <img class="loginBackground" src="@/assets/image/loginBorder.png" alt="">
    <img src="@/assets/image/loginLogo.png" alt="" class="logo">
    <img src="@/assets/image/maobi.png" alt="" class="maobi">
    <div class="from">
      <p class="title">{{ title }}</p>
      <span v-if="status < 3">
        <div class="content" style="text-align: left;top: 2vw;position: relative;">
          <span v-show="status < 3">
            <p style="font-size: 1vw;">{{ firstInput }}</p>
            <input :type="isShow && status == 2 ? 'password' : 'text'"
              :placeholder="status < 2 ? 'info@exampel.com' : ' 半角英数字記号6文字以上'" v-model="inputF">
            <img v-if="status == 2" @click="isShow = !isShow"
              style="width: 1.5vw;position: relative;left: -3vw;top: .2vw;" :src="isShow ? noT : showT" alt="">
          </span>
          <!-- 第二个输入框 -->
          <span v-if="status < 4">
            <p>{{ twoInput }}</p>
            <input @blur="blurInput(1)" v-model="inputT" :type="isShowT && status !== 1 ? 'password' : 'text'"
              :placeholder="status == 0 || status == 2 ? ' 半角英数字記号6文字以上' : ' ワンタイムパスワードを入力してください'">
            <img v-if="status !== 1" @click="isShowT = !isShowT"
              style="width: 1.5vw;position: relative;left:-3vw;top: .2vw;" :src="isShowT ? noT : showT" alt="">
          </span>
          <br>
          <button @click="loginFun()">{{ buttonText }}</button>
          <p class="rightBottomText"><a href="www.baidu.com">バスワ-ドを忘れた方はこちら</a><br>›プライバシ-ポリシ-
          </p>
        </div>
        <p style="text-align: center;font-size: .8vw;top: 2vw;position: relative;" @click="changeStatus()">
          {{ status == 0 ? '該当するアカウントが存在しないため、新規会員登録へ' : '' }}
        </p>
      </span>
      <span v-else>
        <p style="top: 5vw;position: relative;">恭喜注册成功</p>
      </span>
      <!-- <div id="captcha1"></div> -->
      <Vcode :show="codeShow" @success="success" @close="close" style="z-index: 999999999;" />
    </div>
  </div>
</template>
<style scoped>
a {
  color: black;
}

.content>* {
  /* text-align: left; */
  left: 3vw;
  position: relative;
}

.rightBottomText {
  top: -1vw;
  font-size: .7vw;
  left: 18vw;
  line-height: 1.1vw;
}

button {
  background: #7F9997;
  width: 12vw;
  height: 2.5vw;
  top: 2vw;
  border: none;
  transition: 1s ease;
  border-radius: .5vw;
}
button:hover {
  background: #8dd2cc;
}
input {
  background: #fdfaec;
  border: none;
  border-radius: .5vw;
  height: 2.5vw;
  width: 24vw;
  top: -.3vw;
}

input::placeholder {
  color: gray;
}

.from {
  position: relative;
  width: 30vw;
  height: 30vw;
  left: 35vw;
  top: 50vh;
  text-align: center;
  position: absolute;
  transform: translateY(-15vw) scale(.6)
}

.title {
  margin-top: 4vw;
  font-size: 1.3vw;
}

.loginBackground {
  width: 30vw;
  height: 30vw;
  left: 35vw;
  top: 50vh;
  position: absolute;
  transform: translateY(-15vw) scale(.6)
}

.logo {
  width: 7vw;
  /* height: 30vw; */
  left: 52vw;
  top: 61vh;
  position: absolute;
  transform: translateY(-15vw) scale(.6)
}

.maobi {
  width: 9vw;
  /* height: 30vw; */
  left: 44vw;
  top: 64vh;
  position: absolute;
  transform: translateY(-15vw) scale(.6) 
}
</style>
<script>
// import CaptchaMini from 'captcha-mini'
import Vcode from "vue-puzzle-vcode";

export default {
  name: 'loginName',
  components: {
    Vcode
  },
  props: {
  },
  data() {
    return {
      isShow: true,
      isShowT: true,
      showT: require('@/assets/image/showPassWord.png'),
      noT: require('@/assets/image/noPassWord.png'),
      status: 0, //登录0，注册账号1，确认密码2，注册成功3
      twoInput: 'パ-スコ-ド',
      title: 'ログイン',
      firstInput: 'メ-ルアドレス',
      buttonText: 'ログイン',
      codeShow: false,
      fromData: {
        useName: '',
        passWord: '',
        repeatPassWord: '',
        code: '',
      },
      inputF: '',
      inputT: '',
      haveFalse: false,
    }
  },
  watch: {
    status(val) {
      // console.log(val);
      switch (val) {
        case 0:
          this.title = 'ログイン'
          this.firstInput = 'メ-ルアドレス'
          this.twoInput = 'パ-スコ-ド'
          this.buttonText = 'ログイン'
          break
        case 1:
          this.title = '新規登録'
          this.firstInput = 'メ-ルアドレス'
          this.twoInput = 'ワンタイムパスワード'
          this.buttonText = '次に進む'
          break
        case 2:
          this.title = '新規登録'
          this.firstInput = 'パスワード'
          this.twoInput = 'パスワードを確認する'
          this.buttonText = '次に進む'
          break
        case 3:
          // this.title = '新規登録'
          // this.firstInput = 'メ-ルアドレス'
          this.codeShow = true,
            this.twoInput = '私はロボットではありません'
          this.buttonText = '次に進む'
          break
        case 4:
          // this.title = '登録完了いたしました'
          // this.firstInput = 'メ-ルアドレス'
          // this.twoInput = 'パ-スコ-ド'
          this.buttonText = 'ログイン画面に戻る'
          break
      }
    },
    "fromData.passWord"(val) {
      // console.log(val);
      var rules = /^(?:\d+|[a-zA-Z]+|[!@#$%^&*]+)$/
      if (!rules.exec(val)) {
        this.$message.error('密码规则')
        this.haveFalse = true
      } else {
        this.haveFalse = false
      }
    },
    "fromData.repeatPassWord"(val){
      this.rulesFun(val)
    },
  },
  mounted() {
    document.body.style.height = '100vh'
    window.scrollTo({
      top: 0,
      behavior: 'auto' // 平滑滚动
    });

  },
  methods: {
    rulesFun(val){
      if(val!==this.fromData.passWord){
        this.$message.error('重复密码与密码不匹配')
        this.haveFalse = true
      }else{
        this.haveFalse = false
      }
    },
    blurInput() {
      switch (this.status) {
        case 0:
          this.fromData.useName = this.inputF
          this.fromData.passWord = this.inputT
          break
        case 1:
          this.fromData.useName = this.inputF
          this.fromData.code = this.inputT
          break
        case 2:
          this.fromData.passWord = this.inputF
          this.fromData.repeatPassWord = this.inputT
          this.rulesFun(this.fromData.repeatPassWord)
          break
      }
    },
    changeStatus() {
      this.haveFalse = false
      if (this.status == 0) {
        this.status = 1
      }
      else {
        this.status = 0
      }
      this.inputF = this.inputT = ''
    },
    loginFun() {
      if (this.inputF == '' || this.inputT == '' || this.haveFalse == true) { this.$message.error('请填写正确的信息'); } else {
        this.inputF = this.inputT = ''
        if (this.status > 0) {
          this.status += 1
        }
      }
    },
    success() {
      this.codeShow = false; // 通过验证后，需要手动隐藏模态框
    },
    // 用户点击遮罩层，应该关闭模态框
    close() {
      this.codeShow = false;
    },
  }
}
</script>
