<template>
    <div class="maxBox">
        <div class="showNavBar" @mouseenter="showNavBar()"></div>
        <div>
            <div style="width:100vw;height:100vh;background: yellow">
                <div class="leftPage">
                    <galary_first ref="first" class="page1" />
                </div>
                <div class="rightPage">
                    <galary_five ref="five" style="position: absolute;" class="page5" />
                    <galary_four ref="four" style="position: absolute;" class="page4" />
                    <galary_three class="page3" ref="three" style="position: absolute;" />
                    <galary_two @click="testFun()" ref="two" class="page2" style="position: absolute;"
                        :srcList="srcList" />
                    <leftImageArr />
                </div>
            </div>
        </div>
        <div class="index" style="width: 100vw;height: 100vh" @click.stop="donghua()">
            <img src="@/assets/image/galleryBackground.png" style="position: absolute;width: 100vw;" alt=""
                class="galaryBackground">
            <div class="anmiteDoorClass"
                style="transition: 3s ease;position: relative;text-align: center;top: 14vw;z-index: 9;">
                <img src="@/assets/image/doorLine.png" alt="" class="doorLine">
                <img src="@/assets/image/doorLine.png" alt="" class="line">
                <div class="door">
                    <img @mouseover="hoverDoor()" @mouseout="outDoor()" src="@/assets/image/door.png" alt=""
                        class="doorImage">
                </div>
            </div>
            <div class="nameText" style="position: relative;margin: 0vw auto;width: 15vw;height: 15vw">
                <p style="left: 0;">N</p>
                <p style="right: 0">A</p>
                <p style="left: 0;margin-top: 14vw;">L</p>
                <p style="right: 0;margin-top: 14vw;">U</p>
                <div class="smallText">
                    ’s<br>
                    GALLERY
                </div>
                <div class="myFont2" style="position: absolute;top: 80vh;left: 50vw;width: 50vw;left: -7vw;font-family: 'myFont2';">©Copyright 2024 Nagis Choice All Right Reserved Privacy Policy</div>
            </div>
            <!-- <div class="tag">
                <span :class="'tagContent' + index + ' tagAllStyle'" v-for="(item, index) in tagArr" :key="item"
                    :style="`margin-left:${100 / (tagArr.length - 2) / 2}vw`">
                    {{ item }}
                </span>
            </div> -->
          
        </div>
        <scaleImage class="scaleImage" />
    </div>
</template>
<script>
import galary_first from './galary/galary_first.vue';
import galary_two from './galary/galary_two.vue';
import galary_three from './galary/galary_three.vue';
import galary_four from './galary/galary_four.vue';
import galary_five from './galary/galary_five.vue';
import scaleImage from '../components/scaleImage.vue'
import leftImageArr from '../components/leftImageArr.vue'
export default {
    name: 'galary_indexName',
    components: {
        galary_first, galary_two, galary_three, scaleImage, galary_four, galary_five, leftImageArr
    },
    props: {
    },
    data() {
        return {
            tagArr: [
                'test', 'test1', 'test2', 'test3', 'test4', 'test5', 'test6'
            ],
            setId: '',
            showImage: 'test',
            srcList: [
                // require('@/assets/test/test.png'),
                // require('@/assets/test/test.png'),
                // require('@/assets/test/test.png'),
                // require('@/assets/test/test3.png'),
                // require('@/assets/test/test4.jpg'),
                // require('@/assets/test/test2.png'),
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
            ],
            // scrollStatus: '-1',//-1.无操作，0.正常翻页，1.放大图片，2.luna作品集，3.nagi作品集，4.名片，5.最后一页左滑
            page: 0,
            scrollY: 0,
            wheelIfStop: true,
            setTimeId: '',
            ifStop: true,
            copyStatus: '',
            status: 0,
            lookAuthor: '',
            nagisWorks: [3, 4, 5],
            lunasWorks: [3, 3],
            pageZIndex: [0, 1, 2],
            ifClick: true,
            i: 100
        }
    },
    watch: {
        page(val) {
            const dom = document.getElementsByClassName('romveX')[0]
            if (val == 2 && this.lookAuthor == 'nagi') {
                // dom.style.transition = '1s cubic-bezier(0, -0.06, .04, .83)'
                dom.style.transform = 'translateX(10vw)'
                dom.style.opacity = '1'
                dom.style.zIndex = '11'
            }
            if (val == 1 && this.lookAuthor == 'luna') {
                const dom = document.getElementsByClassName('galaryTwoText2P')
                Object.values(dom).map((item, index) => {
                    setTimeout(() => {
                        item.style.opacity = '1'
                        item.style.filter = 'blur(0vw)'
                    }, 500 * index);
                })
            }
        },
    },
    mounted() {
        document.body.style.height = '100vh'
        let scrollbox = document.getElementsByClassName("scrollbox")[0];
        const dom = document.getElementsByClassName('romveX')[0]
        scrollbox.style.transform = 'translateY(0)'
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
        this.$bus.$on('changeScrollStatus', (e) => {
            this.scrollStatus = e
        })
        this.$bus.$on('testChange', (e) => {
            this.testFun(e)
        })
        this.$bus.$on('openImage', () => {
            this.copyStatus = this.scrollStatus
            this.scrollStatus = '1'
        })
        this.$bus.$on('closeImage', () => {
            this.scrollStatus = this.copyStatus
        })
        this.$bus.$on('author', e => {
            this.lookAuthor = e
        })
        window.addEventListener('wheel', (e) => {
            if (this.$router.currentRoute.fullPath !== '/galary_index') {
                return
            }
            const ifUp = e.deltaY
            if (this.ifStop == true && this.lookAuthor !== '') {
                if (this.page == 2 && this.lookAuthor == 'nagi') {
                    console.log(Number(dom.style.transform.match(/\d+/g)[0]));
                    if (Number(dom.style.transform.match(/\d+/g)[0]) > 40) {
                        this.i = 100
                        if (dom.style.transform.indexOf('-') > -1) {
                            this.testFun(this.page - 1)
                        } else {
                            dom.style.transform = `translateX(${10}vw)`
                        }
                        return
                    }
                    dom.style.transform = `translateX(${this.i / 10}vw)`
                    this.$forceUpdate()
                    // console.log(dom.getBoundingClientRect().left);
                    this.i += ifUp
                } else {
                    if (ifUp > 0 && this.page < 3) {
                        console.log('加');
                        this.testFun(this.page + 1)
                    } else if (ifUp < 0 && this.page > -1) {
                        console.log('减');
                        this.testFun(this.page - 1)
                    }
                }
            }
            this.ifStop = false
            if (this.setTimeId !== '') {
                clearTimeout(this.setTimeId)
            }
            this.setTimeId = setTimeout(() => {
                this.ifStop = true
            }, 500);
        })
    },
    methods: {
        showNavBar(){
            this.$bus.$emit('showNarBar',true)
        },
        changeImage(val) {
            this.showImage = val
            console.log(val);
        },
        float(index) {
            setTimeout(() => {
                const dom = document.getElementsByClassName('tagContent' + index)[0]
                this.setId = setInterval(() => {
                    let num = Math.random() * 2 - 1
                    let num1 = Math.random() * 2 - 1
                    dom.style.transform = `translateX(${num}vw) translateY(${num1}vw)`
                }, 3000)
            }, (index - 1) * 500)
        },
        donghua() {
            this.status = 1
            const dom = document.getElementsByClassName('doorImage')[0]
            const dom2 = document.getElementsByClassName('anmiteDoorClass')[0]
            const dom3 = document.getElementsByClassName('index')[0]
            dom2.style.transform = 'scale(20)'
            // const dom1 = document.getElementsByClassName('line')[0]
            dom.style.transform = "rotateY(100deg)"
            // dom2.style.opacity = "0"
            setTimeout(() => {
                dom3.style.opacity = "0"
                setTimeout(() => {
                    dom3.style.display = 'none'
                }, 1000);
            }, 2000);
            // dom2.style.transform = "scale(30)"
            // dom1.style.maskPosition = "0vw 0vw"
            // setTimeout(() => {
            //     dom2.style.opacity = "0"
            // }, 1500)
            // setTimeout(() => {
            //     clearInterval(this.setId)
            //     dom2.style.display = "none"
            // }, 2500)
            // window.scrollTo({
            //     top: 0,
            //     behavior: 'auto'
            // });

        },
        hoverDoor() {
            if (this.status == 0) {
                const dom = document.getElementsByClassName('doorImage')[0]
                const dom1 = document.getElementsByClassName('line')[0]
                dom.style.transform = 'rotateY(30deg) skewY(-5deg)'
                dom1.style.maskPosition = "-9vw 0vw"
            }
        },
        outDoor() {
            if (this.status == 0) {
                const dom = document.getElementsByClassName('doorImage')[0]
                const dom1 = document.getElementsByClassName('line')[0]
                dom.style.transform = 'rotateY(0deg) skewY(0deg)'
                dom1.style.maskPosition = "-10.5vw 0vw"
            }
        },
        // testFun(val) {
        //     if (this.ifClick == true) {
        //         this.ifClick = false
        //         let data
        //         if (this.lookAuthor == 'luna') {
        //             if (!this.lunasWorks[val]) {
        //                 console.log('不存在页面');
        //                 return
        //             } else {
        //                 data = this.lunasWorks[val]
        //             }
        //         } else if (this.lookAuthor == '') {
        //             return
        //         } else if (this.lookAuthor == 'nagi') {
        //             if (!this.nagisWorks[val]) {
        //                 console.log('不存在页面');
        //                 return
        //             } else {
        //                 data = this.nagisWorks[val]
        //             }
        //         }
        //         let leftArr
        //         if (this.page < val + 1) {
        //             leftArr = document.getElementsByClassName('left')
        //             for (let i = 3; i < 8; i++) {
        //                 if (i <= data) {
        //                     const dom = document.getElementsByClassName('rotateBox' + (i - 1))[0]
        //                     const dom1 = document.getElementsByClassName('rotateBoxBack' + (i - 1))[0]
        //                     const dom2 = document.getElementsByClassName('box' + (i - 1))[0]
        //                     dom2.style.zIndex = '1'
        //                     if (dom.style.transform !== 'rotateY(180deg)') {
        //                         dom.style.transform = dom1.style.transform = `rotateY(90deg)`
        //                         if (i == data) {
        //                             setTimeout(() => {
        //                                 dom2.style.zIndex = '9'
        //                                 dom.style.transform = dom1.style.transform = `rotateY(180deg)`
        //                             }, 1000);

        //                         }
        //                     }
        //                 }
        //             }
        //         } else {
        //             console.log('上一页');
        //             leftArr = document.getElementsByClassName('right')
        //             for (let p = 4; p > 0; p--) {
        //                 const dom = document.getElementsByClassName('rotateBox' + (p + 1))[0]
        //                 const dom1 = document.getElementsByClassName('rotateBoxBack' + (p + 1))[0]
        //                 const dom2 = document.getElementsByClassName('box' + (p + 1))[0]
        //                 console.log(dom2.style.zIndex,p)
        //                 dom2.style.zIndex = '1'
        //                 if (p <= data) {
        //                     if (dom.style.transform !== 'rotateY(0deg)' || dom.style.transform !== '') {
        //                         dom.style.transform = dom1.style.transform = `rotateY(90deg)`
        //                         setTimeout(() => {
        //                             dom.style.transform = dom1.style.transform = `rotateY(0deg)`
        //                         }, 1000);
        //                     }
        //                     if (p == data+1) {
        //                         dom2.style.zIndex = '9'
        //                     }
        //                 }
        //             }
        //         }
        //         this.page = val
        //         Object.values(leftArr).map((item) => {
        //             item.style.width = '100vw'
        //             item.style.opacity = '0.5'
        //             item.style.left = '0vw'
        //             setTimeout(() => {
        //                 item.style.width = '10vw'
        //                 item.style.opacity = '1'
        //                 item.style.left = '45vw'
        //             }, 1000);
        //         })
        //         setTimeout(() => {
        //             this.ifClick = true
        //         }, 1000);
        //     }
        // },
        testFun(val) {
            if (this.ifClick == true) {
                let data
                let leftArr
                let currentPage
                if (this.lookAuthor == 'luna') {
                    if (!this.lunasWorks[val]) {
                        console.log('不存在页面');
                        return
                    } else {
                        data = this.lunasWorks[val]
                        currentPage = this.lunasWorks[this.page]
                    }
                } else if (this.lookAuthor == '') {
                    return
                } else if (this.lookAuthor == 'nagi') {
                    if (!this.nagisWorks[val]) {
                        console.log('不存在页面');
                        return
                    } else {
                        data = this.nagisWorks[val]
                        currentPage = this.nagisWorks[this.page]
                    }
                }
                this.ifClick = false
                this.$forceUpdate()
                if (this.page < val) {
                    //下一页
                    leftArr = document.getElementsByClassName('left')
                    const domArr = document.getElementsByClassName('pageBox')
                    const dom1 = document.getElementsByClassName('rotateBox' + (data - 1))[0]
                    const dom2 = document.getElementsByClassName('rotateBoxBack' + (data - 1))[0]
                    const domT = document.getElementsByClassName('box' + (data - 1))[0]
                    // const domTH = document.getElementsByClassName('box' + (data))[0]
                    const domF = document.getElementsByClassName('box' + (currentPage - 1))[0]
                    const domF1 = document.getElementsByClassName('rotateBox' + (currentPage - 1))[0]
                    const domF2 = document.getElementsByClassName('rotateBoxBack' + (currentPage - 1))[0]
                    Object.values(domArr).map((item) => {
                        item.style.zIndex = '1'
                        if (item == domF) {
                            item.style.zIndex = '2'
                        }
                    })
                    dom1.style.transform = dom2.style.transform = 'rotateY(90deg)'
                    if (this.page == 0) {
                        domF1.style.transform = domF2.style.transform = 'rotateY(90deg)'
                        // domF.style.zIndex = '3'
                        // domTH.style.zIndex = '2'
                    }
                    setTimeout(() => {
                        setTimeout(() => {
                            domT.style.zIndex = '9'
                        }, 300);
                        dom1.style.transform = dom2.style.transform = 'rotateY(180deg)'
                        if (this.page == 0) {
                            domF1.style.transform = domF2.style.transform = 'rotateY(180deg)'
                        }
                    }, 500);
                } else {
                    const domLast = document.getElementsByClassName('romveX')[0]
                    domLast.style.transform = 'translateX(50vw)'
                    domLast.style.opacity = '0'
                    domLast.style.zIndex = '0'
                    //上一页
                    leftArr = document.getElementsByClassName('right')
                    const domArr = document.getElementsByClassName('pageBox')
                    const domT = document.getElementsByClassName('box' + (currentPage - 1))[0]
                    const dom1 = document.getElementsByClassName('rotateBox' + (currentPage - 1))[0]
                    const dom2 = document.getElementsByClassName('rotateBoxBack' + (currentPage - 1))[0]
                    const domF = document.getElementsByClassName('box' + (data - 1))[0]
                    const domF1 = document.getElementsByClassName('rotateBox' + (data - 1))[0]
                    const domF2 = document.getElementsByClassName('rotateBoxBack' + (data - 1))[0]
                    Object.values(domArr).map((item) => {
                        item.style.zIndex = '1'
                        if (item == domF) {
                            item.style.zIndex = '2'
                        }
                    })
                    dom1.style.transform = dom2.style.transform = 'rotateY(90deg)'
                    if (this.page == 1) {
                        domF1.style.transform = domF2.style.transform = 'rotateY(90deg)'
                    }
                    domT.style.zIndex = '9'
                    setTimeout(() => {
                        dom1.style.transform = dom2.style.transform = 'rotateY(0deg)'
                        if (this.page == 1) {
                            setTimeout(() => {
                                domF.style.zIndex = '10'
                            }, 300);
                            domF1.style.transform = domF2.style.transform = 'rotateY(0deg)'
                            // domLast.style.transform = 'translateX(100vw)'
                        }
                    }, 500);
                }
                setTimeout(() => {
                    this.page = val
                    this.ifClick = true
                }, 2000);
                Object.values(leftArr).map((item) => {
                    item.style.width = '100vw'
                    item.style.opacity = '0.5'
                    item.style.left = '0vw'
                    setTimeout(() => {
                        item.style.width = '10vw'
                        item.style.opacity = '1'
                        item.style.left = '45vw'
                    }, 1000);
                })
            }
        }
    }
}
</script>
<style scoped>
.showNavBar{
    width: 100vw;
    height: 3vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 17;
}
.pageBox {
    z-index: 0;
}

.two {
    transform-origin: left;
    /* transform:  */
    transition: all 1s ease
}

.scaleImage {
    opacity: 0;
    display: none;
    transition: all 1s ease;
}

.maxBox>div {
    position: absolute;
}

.leftPage,
.rightPage {
    width: 50vw;
    display: inline-block;
    position: relative;
    top: 0%;
    height: 100vh;
    /* box-:  */
}

.tag {
    position: absolute;
    top: 40vw;
    left: 0;
}

.tagAllStyle {
    display: inline-block;
    font-size: .7vw;
    font-weight: 600;
    color: #73665e;
    /* transform:  */
    transition: all 3s ease;
}

.smallText {
    /* margin: 0 auto; */
    text-align: center;
    position: relative;
    top: 14.7vw;
    font-family: fangsong;
    color: #73665e;
    font-size: .8vw;
    font-weight: 600;
}


.nameText p {
    position: absolute;
    font-size: 4vw;
    font-family: fangsong;
    color: #73665e;
    margin-top: 1vw
}

.door {
    /* text-align: center; */
    z-index: 999;
    position: relative;
}

.doorImage {
    width: 6.2vw;
    position: relative;
    /* top: 15vw; */
    transform-origin: right;
    transition: all 1s cubic-bezier(0, -0.06, .04, .83);
    margin: 0 auto;
    /* : ; */
    /* left: -.34vw; */
    transform: rotateY(0deg) skewY(0deg);
}

.index {
    z-index: 16;
    transform-origin: 50.5vw 20vw;
    transition: 1s ease;
}

/* .doorImage:hover { */
/* transform: rotateY(30deg) skewY(-5deg); */
/* } */

.doorLine {
    width: 6.2vw;
    position: absolute;
    left: 47vw;
    filter: blur(.4vw) drop-shadow(0 0 1vw rgba(0, 0, 0, 0.5));
}

.line {
    filter: blur(.2vw);
    width: 7vw;
    height: 4vw;
    position: absolute;
    left: 45.7vw;
    top: 9vw;
    transform: rotateX(180deg) skewY(30deg) rotateZ(-30deg);
    mask-position: -10.5vw 0vw;
    mask-repeat: no-repeat;
    mask-size: 10.5vw 4vw;
    mask-image: url('@/assets/image/door.png');
    transition: all 2s cubic-bezier(0, -0.06, .04, .83);
}

.doorBack {
    position: absolute;
    width: 6.7vw;
    top: 13vw;
    left: 44vw;
}

.doorBackWhite {
    position: absolute;
    width: 8.2vw;
    top: 16vw;
    left: 46vw
}
</style>
