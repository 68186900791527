<template>
    <div  style="width: 100vw;height: 300vh;background: #fdf9f5;z-index: 0;">
      <div class="topMubu">
            <div class="leftmubu"></div>
            <div class="rightmubu"></div>
        </div>
        <img src="@/assets/image/weimu.png" alt="" class="weimu">
        <!-- <el-input v-model="columnNum"></el-input> -->
        <img src="@/assets/image/shopLogo.png" alt="" @click="back()" class="shopLogo">
        <p class="form font4">Home > About > CIRCUS DOLL > Shops > Products </p>
        <componentsDetail style="width: 40vw;left: 30vw;position: relative;z-index: 9;" />
    </div>
</template>
<style scoped>
.weimu{
  width: 100vw;
  position: absolute;
  z-index: 1;
  top:8vw;
}
.form {
    position: relative;
    color: #fe5126;
    left: 15vw;
    font-size: .6vw;
    font-weight: 600;
    top: -5vw;
}
.shopLogo {
    width: 3vw;
    left: 48.5vw;
    position: relative;
    top: -7vw;
    z-index: 2;
}
.topMubu {
    position: relative;
    width: 100vw;
}

.leftmubu,
.rightmubu {
    width: 50vw;
    height: 12vw;
    position: relative;
    top: -5vw;
    background-color: #ca482a;
    display: inline-block;
    transform: skewY(-7deg);
    z-index: 2;
}

.rightmubu {
    transform: skewY(7deg);
}
</style>
<script>
import componentsDetail from './componentsDetail.vue';


export default {
  name: 'shop_detailsName',
  components:{componentsDetail},
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
    document.body.style.height = '230vh'
        window.scrollTo({
            top: 0,
            behavior: 'auto' // 平滑滚动
        });
  },
  methods: {
    back(){
        console.log('1');
        // window.history.go(-1)
        this.$router.back()
    },
  }
}
</script>
