<template>
    <div  class="box3 pageBox">
        <div class="rotateBox3" @click="test(4)" style="">
        <shadow_name style="left: -50vw;" ref="shadow_name"  :isLeft="false" />
            <div class="imageListGallryThree" >
                <img :src="item" class="image" 
                alt="" 
                v-for="(item, index) in srcList" 
                :key="item"
                    @click.stop="changeShow(item, index)">
            </div>
            <div class="backTop" @click.stop="backTopFun()">
                <img src="@/assets/image/backFirst.png"  style="width: 5vw;" alt="">
            </div>
        </div>
        <div class="rotateBoxBack3" @click="test(0)">
            <div class="rotateBack">
        <shadow_name ref="shadow_name"  :isLeft="true"/>
                <div>
                    <img src="@/assets/image/formImage.png" alt="" class="formImageBack">
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
.backTop:hover{
    transform: translateX(0);
}
.backTop{
    width: 5vw;
    position: fixed;
    z-index: 10;
    top: 87vh;
    transform: translateX(1vw);
    transition: .3s cubic-bezier(.5,1.5,1,1);
    left: 45vw;
}
.formImageBack {
    width: 40vw;
    position: absolute;
    top: 40vw;
    right: 5vw;
}
.rotateBox3 {
    width: 50vw;
    height: 100vh;
    background: #a6998f;
    overflow: scroll;
    position: absolute;
    z-index: 3;
    transform-origin: left;
    transition: 1s cubic-bezier(.66,.7,1,1);
    backface-visibility: hidden;
    // box-shadow: -1vw 0vw 10vw rgba(0,0,0,.5);
}

.rotateBoxBack3 {
    width: 50vw;
    height: 100vh;
    background: #faf1d8;
    position: absolute;
    transform-origin: left;
    z-index: 2;
    transition: 1s cubic-bezier(.66,.7,1,1);
    box-shadow: 1vw 0 1vw rgba(0,0,0,.5);
}

.box3 {
    transform-style: preserve-3d;
    perspective: 90vw;
}

.rotateBack {
    transform: rotateY(180deg);
    width: 50vw;
}

.image {
    position: relative;
    width: 10vw;
    transition: scale .5s ease;
    margin-top: 2vw;
    border-radius: .2vw;
}

.image:hover {
    /* transform: scale(1.1); */
    scale: 1.05
}

.imageListGallryThree {
    position: relative;
    left: 5vw;
    padding-left: 1vw;
    column-count: 3;
    top: 2vw;
    // overflow: auto;
    width: 36vw;
    transition: all 1s cubic-bezier(0, -0.06, .04, .83)
}
.linglan path{
    stroke-dashoffset: 2000;
    stroke-dasharray: 2000;
    transition: 5s ease;
}
</style>
<script>
// import anime from 'animejs';
import shadow_name from '../../components/shadow.vue'

export default {
    components: {
        shadow_name,
    },
    props: {
        // srcList: {
        //     typeof: Array,
        // }
    },
    data() {
        return {
            url: require('@/assets/test/test.png'),
            srcList:[
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            require('@/assets/test/test.png'),
            ],
        }
    },
    mounted() {
        this.listAnime()
    },
    methods: {
        backTopFun(){
            // this.$nextTick(()=>{
            const dom =  document.getElementsByClassName('rotateBox3')[0]
            dom.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            // })
        },
        test(index) {
            const dom3 = document.getElementsByClassName('pathLinglan')[0]
            setTimeout(() => {
                dom3.style.strokeDashoffset = 0
            }, 500);
            this.$bus.$emit('testChange',index)
            // if(index==2){
            //     const dom =document.getElementsByClassName('box4')[0]
            //     dom.style.zIndex = '2'
            // }
        },
        changeShow(url, index) {
            const dom = document.getElementsByClassName('image')[index]
            const width = dom.getBoundingClientRect().width
            const height = dom.getBoundingClientRect().height
            const proportion = width / height
            // if()
            let imgWidth = ''
            let imgHeight = ''
            if (proportion < 17 / 23) {
                imgWidth = '17vw'
                imgHeight = ''
            } else {
                imgHeight = '23vw'
                imgWidth = ''
            }

            const data = {
                // url: url,
                imgWidth: imgWidth,
                imgHeight: imgHeight,
                index:index,
            }
            this.$bus.$emit('changeImage', data)
        },
        listAnime() {
            // anime({
            //     targets: '.image',
            //     top: '0',
            //     duration: '200',
            //     easing: 'easeInOutExpo',
            //     delay: function (el, i) {
            //         return i * 200;
            //     },
            //     endDelay: function (el, i, l) {
            //         return (l - i) * 200;
            //     }
            // })
        }
    }
}
</script>