const contData = [
    'プライバシーポリシー',
    '電子商務有限会社 Nagi‘s Choice（以下、「当社」とします）は、お客様の個人情報の保護に努めています。',
    '本ウェブサイトのサービス利用にあたり、以下の通りポリシーを定めます。',
    '■ 基本方針',
    '当社は、個人情報の大切さを理解し、法令を遵守して安全に管理します。',
    '個人情報を適切に扱うことは、私たちの社会的責任と考えています。',
    '■ 適用範囲',
    'このプライバシーポリシーは、当サイトを通じて取得するお客様の個人情報および関連情報に適用されます。',
    '■ 個人情報の利用目的',
    '当社が個人情報を利用するのは、次のような目的のためです：',
    '・ご本人確認',
    '  お問い合わせやご意見への対応',
    '  メンテナンス、サービスに関する重要なお知らせのご連絡',
    '  キャンペーンやアンケートの実施',
    '  サービスの改善や開発、マーケティング',
    '  ご承諾いただいた上での、提携先への情報提供',
    '  利用規約違反の調査',
    '  詳細資料の発送や日程の連絡などの対応',
    '  その他個別に承諾いただいた目的',
    '■ 個人情報の管理',
    'お客様の個人情報は、正確かつ安全に管理します。不正アクセスや漏洩を防ぐため、必要かつ適切なレベルの',
    'セキュリティ対策をします。',
    '■ 個人情報の第三者提供',
    '当社は、以下のような正当な理由がある場合を除き、個人情報を第三者に提供することはありません：',
    '・法律に基づく要求がある場合',
    '  人の生命、身体、財産の保護が必要な場合',
    '  公衆衛生や子どもの健全な育成が必要な場合',
    '  法令に定められた事務に協力する場合',
    'なお、業務の遂行に必要な範囲で、他の事業者に業務委託をする場合があります。'
]

export default contData