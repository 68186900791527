<template>
  <div style="background: #fdfaec;width: 100vw;height: 100vh;">
    <div>
      <div class="imgBox_myPage">
        <img src="@/assets/image/zhen.png" style="
                width: .5vw;
                top: .5vw;
                position: absolute;
                left: 25vw;
                z-index: 2;
                transform: rotateZ(20deg);
                " alt="">
        <div class="content_myPage font1">
          <one_page @clickItem="changePageFun"  class="myPageDom" />
          <two_page @clickItem="changePageFun"  class="myPageDom" />
          <three_page @clickItem="changePageFun"  class="myPageDom" />
          <four_page @clickItem="changePageFun"  class="myPageDom" />
          <five_page @clickItem="changePageFun"  class="myPageDom" />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.imgBox_myPage {
  background-image: url('@/assets/image/myPageBackground.png');
  background-size: 30vw 40vw;
  width: 30vw;
  left: 35vw;
  top: 5vw;
  height: 40vw;
  position: absolute;
}

.content_myPage {
  mask-image: url('@/assets/image/myPageTextBackground.png');
  mask-size: 28vw 39vw;
  background: #fff;
  width: 28vw;
  position: absolute;
  text-align: left;
  left: 1vw;
  font-size: .7vw;
  top: .7vw;
  height: 39vw;
  color: #656d6c;
  font-weight: 400;
}

.myPageDom {
  display: none;
  transition: 1s ease;
  opacity: 0;
}
</style>
<script>
import one_page from './myPageChildren/one_page.vue'
import two_page from './myPageChildren/two_page.vue'
import three_page from './myPageChildren/three_page.vue'
import four_page from './myPageChildren/four_page.vue'
import five_page from './myPageChildren/five_page.vue'

export default {
  name: 'myPageNmae',
  components: { one_page, two_page,three_page,four_page,five_page },
  props: {
  },
  data() {
    return {
      myStatus: 0,
    }
  },
  watch: {
    myStatus(befor, after) {
      this.changeAnimte(befor, after)
    },
  },
  mounted() {
    // this.myStatus =1
    this.changeAnimte(0, 0)
    document.body.style.height = '100vh'
  },
  methods: {
    changePageFun(index) {
      console.log(index);
      this.myStatus = index
      this.$forceUpdate()
    },
    changeAnimte(befor, after) {
      console.log(befor, after);
      const dom1 = document.getElementsByClassName('myPageDom')[after]
      const dom2 = document.getElementsByClassName('myPageDom')[befor]
      // dom1.style.display = 'none'
      dom2.style.display = 'block'
      setTimeout(() => {
        dom2.style.opacity = '1'
      }, 1);
      if (after !== befor) {
        dom1.style.opacity = '0'
        setTimeout(() => {
          dom1.style.display = 'none'
        }, 1000);
      }
    }
  }
}
</script>
