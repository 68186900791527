<template>
    <div style="position: absolute;top: 0;">
      <PayPal
        amount="1"
        currency="JPY"
        :client="paypal"
        env="sandbox"
      >
      </PayPal>
    </div>
  </template>
  
  <script>
  // import PayPal from "vue-paypal-checkout";
  
  export default {
    data() {
      return {
        paypal: {
          sandbox:
            "AYP0ZsH7QDZ9HnqtgfsjRBpXMQ0vOEmmT_oMNuDk8161A3zOxCjzLNSvvNb2LvouTlnEkauCHKm1z6Ww",
          production:
            "EEaTC43c9OZpQBPgE3k7oQxLK6JMI6Kcq-jCmwHeUMxKNugplMZdICBJzfcGw_UKVOZQd1EidrDhGyvZ",
        },
      };
    },
    components: {
      // PayPal,
    },
  };
  </script>
  
  <style scoped>
  </style>
  