<template>
  <div style="width: 100vw;height: 100vh;background: rgba(0,0,0,0.5);" id="carId" @click.stop="closeCar()">
    <div class="carBox1" style="background: #fdf9f5;width: 40vw;height: 100vh;overflow: scroll">
      <p class="title">ショッピングカード</p>
      <div class="goodsListBox">
        <div class="goodsListAddCar" v-for="(item, index) in carList" :key="item"
          :style="`border-bottom:${index == (carList.length - 1) ? '.1vw solid #d4c6a7' : 'none'}`">
          <el-image style="width: 6vw;height: 6vw;
            position: relative;
            top: 50%;
            transform: translate(0,-50%);
            display: inline-block
            " fit="cover" :src="item.url"></el-image>
          <div class="centerBox">
            <span style="font-size: .7vw;
              overflow-wrap: break-word;
              word-break: break-all;
              line-height: .8vw;
              display: block;
              margin-top: .5vw">{{
                item.title }}</span>
            <p style="
              font-size: .7vw;
              margin-top: .5vw;
              ">{{ '￥' + item.price }}</p>
            <p style="
              font-size: .7vw;
              font-weight: 600;
              ">{{ '数量：' + item.num }}</p>
            <p style="
            position: absolute;
            bottom: .5vw;font-size: .8vw;
            ">CIRCUS DOLL</p>
          </div>
          <div class="rightBox">
            <div class="step">
              <span @click.stop="item.num>0?item.num-=1:''">-</span>
              <span style="font-size: .7vw;">{{ item.num }}</span>
              <span @click.stop="item.num+=1">+</span>
            </div>
            <div class="coumt">
              <span style="color: #000;font-size: .8vw;">{{ '￥' + (item.num*Number(item.price)) }}</span>
              <span style="font-size: .5vw;margin-left: .5vw;">税込</span>
            </div>
            <i class="el-icon-delete" @click.stop="delItem(index)"></i>
          </div>
        </div>
      </div>
      <div class="subtotal">
        <p style="font-size: .7vw;font-weight: 600;">小计</p>
        <p style="font-size: 1vw;">{{ '￥' + subtotalFun() }}</p>
        <button style="padding: .5vw 4vw;margin-left: -5vw;border: none;background: #e1d0a7;color: #fff;" @click="goPay()">
          決済に進む</button>
      </div>
      <div class="carTip">
        <p style="font-size: .8vw;color: #a6a4a0;">※別途送料は決算時に加算されます。</p>
        <div style="font-size: .8vw;line-height: 1.5vw;">
          ▼ お支払い方法<br>
          <p style="margin: 1vw 0;"></p>
          ・PAYPAL決済と銀行振込（ゆうちょ銀行 | 三菱UFJ）<br>
          <p style="margin: 1vw 0;"></p>
          ▪PayPal:<br>
          PayPalに登録いただいているクレジットカードやデビットカード、<br>
          銀行口座等でお支払いすることになります。<br>
          <p style="margin: 1vw 0;"></p>
          ▪銀行振込：<br>
          振込先情報は、当店でご注文を確認、商品の確保完了後<br>
          「振込・ゆうちょ銀行へのお支払いについて」という件名のメールを差し上げます（通常2営業日以内）。<br>
          口座や期日などの詳細内容をご確認後、お支払いをお願いいたします。<br>
        </div>
        <img src="@/assets/image/shopDetailLog.png" style="
          width: 5vw;margin-left: 15.5vw;margin-top: 4vw;
        " alt="">
        <img src="@/assets/image/laoshu.png" style="
     width: 8vw;
    position: relative;
    top: -21vw;
    right: -11vw;
    opacity: 0.3;
    transform: rotateZ(-10deg);
        " alt="">
      </div>
    </div>
    <PayPalButton @payment-success="handlePaymentSuccess" @payment-error="handlePaymentError" />
  </div>
</template>
<style scoped>
.carBox1::-webkit-scrollbar {
  width: .1vw;
}

.carTip {
  margin-left: 3vw;
}

.subtotal {
  position: relative;
  width: 10vw;
  left: 30vw;
  text-align: center;
}

.step {
  border: .1vw solid #ab9e7d;
  width: 6vw;
  height: 1.5vw;
  position: absolute;
  right: 2vw;
  bottom: 2.6vw;
}

.step>span {
  display: inline-block;
  line-height: 1vw;
  margin-left: 1.1vw;
}

.centerBox {
  height: 80%;
  width: 13vw;
  position: relative;
  left: 8vw;
  top: -5vw;
}

.el-icon-delete {
  font-size: .7vw;
  right: 0;
  bottom: 3vw;
  position: absolute;
}

.coumt {
  position: absolute;
  right: 2vw;
  bottom: 1vw;
  text-align: center;
  width: 6vw;
}

.coumt>span {
  display: inline-block
}

.rightBox {
  width: 14vw;
  /* background: red; */
  height: 10vw;
  top: -14.8vw;
  margin-left: 21vw;
  position: relative;
  color: #ab9e7d;
}

.goodsListBox {
  width: 35vw;
}

.goodsListAddCar {
  width: 35vw;
  height: 10vw;
  margin-left: 2vw;
  border-top: .1vw solid #d4c6a7;
  transition: 1s ease;
}

#carId {
  position: fixed;
  z-index: 9999999999;
  opacity: 0;
  display: none;
  transition: 1s ease;
}

.carBox1 {
  margin-left: 60vw;
}

.title {
  color: #ab9e7d;
  font-size: .7vw;
  padding: 1vw;
  font-weight: 600;
}
</style>
<script>
import PayPalButton from '@/components/payButton.vue';

export default {
  components:{PayPalButton},
  props: {
  },
  data() {
    return {
      carList: [
        {
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          num: 2,
          title: '测试cecshi/test the text/jhjakjsjk/2134567862132',
          price: '18000',
        },
        {
          url: 'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
          num: 1,
          title: '测试cecshi/test the text/jhjakjsjk/2134567862132/第二个',
          price: '18',
        },
        {
          url: 'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
          num: 1,
          title: '测试cecshi/test the text/jhjakjsjk/2134567862132/第二个',
          price: '18',
        },
      ]
    }
  },
  mounted() {
  },
  methods: {
    delItem(index) {
      const dom = document.getElementsByClassName('goodsListAddCar')[0]
      dom.style.transform = 'translateX(40vw)'
      setTimeout(() => {
        this.carList.splice(index,1)
      }, 1000);
    },
    subtotalFun(){
      var data = 0
      this.carList.map((item)=>{
        data += item.num*item.price
      })
      return data
    },
    closeCar(){
      const dom =document.getElementById('carId')
      dom.style.opacity = '0'
      setTimeout(() => {
        dom.style.display = 'none'
      }, 1000);
    },
    goPay(){
      this.$router.push({
        path:'/payPage',
        query:{data:this.carList,test:1}
      })
    }
  }
}
</script>
