<template>
    <div style="position: relative;color: rgba(0,0,0,.5);">
        <div class="contentBackground" style="position: relative">
            <img src="@/assets/image/contentBackground.png" id="firstImage" style="width: 100vw;margin-top:45vw" alt="">
        </div>
        <div class="transform1 font2"
            style="position: absolute;z-index: 90;left: 61vw;top: 92vw;width: 30vw;transition: 2s ease;transform: translate(20vw,-10vw);opacity: 0;filter: blur(.3vw);">
            <img src="@/assets/image/textBackground.png" style="width: 25vw;" alt="">
            <!-- <span style="">凪とは、<br> 風がやんで、波が穏やかになることを指す言葉。</span> -->
            <p style="position: relative;
    top: -10vw;
    line-height: 2vw;
            font-weight: 600;
    font-size: 0.65vw;
    text-align: center;
    margin-left: -3vw;">
                <span style="font-size: 2vw;left: -7.5vw;position: relative;color: #a78f7d;font-family:">凪</span><span
                    style="position: relative;left: -7.2vw;">とは、</span><br> 風が止んで、波が静まり
                <br>
                心が穏やかになることを指す言葉
            </p>
        </div>
        <div id="transform3" style="position: absolute;
    z-index: 90;
    left: 22vw;
    top: 107vw;
    width: 17vw;
    transition: 1s;
    transform: translateX(-100vw);" :class="`${show1 ? '' : 'transform3'}`">
            <img src="@/assets/image/textBackground.png" style="width: 17vw;height: 10vw" alt="">
            <p style="position: relative;
            font-weight: 600;
    top: -8.5vw;
    line-height: 1.5vw;
    font-size: .6vw;
    text-align: left;left: 3vw" class="font2">
               <span style="font-size: .8vw;color: rgb(108, 122, 122);"> Nagi’ Choiceは</span>
               <br>心が喜ぶ「気持ちいい選択」を意味しています
            </p>
        </div>
        <div class="chazihua" style="position: absolute;z-index: 91;left: 36vw;top: 112.5vw;height: 14vw;">
            <img src="@/assets/image/chazihua.png" style="width: 12vw;transition: 1s ease;" class="transform2" alt="">
        </div>
        <div class="maozihua.png" style="position: absolute;z-index: 91;left: 18vw;top: 117vw;">
            <img src="@/assets/image/maozihua.png" style="width: 12vw;transition: 1s ease;" alt="" class="transform2">
        </div>
        <div class="text_twice font2" style="position: absolute;font-size: .7vw;top: 142vw;left: 52vw;z-index: 14;">
            <!-- <div v-for="item in 4" :key="item"> -->
            <p class="changeBackgroundWidth">始まりは、布花アクセサリーでした<span></span> </p>
            <!-- </div> -->
            <p class="changeBackgroundWidth">これからのNagi’s Choiceは、<span></span></p>
            <p class="changeBackgroundWidth">これまでの小さな一歩を大切にしながら<span></span></p>
            <p class="changeBackgroundWidth"> 「自分が好き」と思えるモノづくりを目指します <span></span></p>
        </div>
        <div class="text_twice font2" style="position: absolute;font-size: .7vw;top: 120vw;left: 57vw;z-index: 14;">
            <!-- <div v-for="item in 4" :key="item"> -->
            <p class="changeBackgroundWidth">ものが溢れる世の中<span></span> </p>
            <!-- </div> -->
            <p class="changeBackgroundWidth">本当に自分が好きなものに囲まれたい<span></span></p>
            <p class="changeBackgroundWidth">そんな思いで<span></span></p>
            <p class="changeBackgroundWidth"> Nagi’s Choiceは生まれました <span></span></p>
        </div>
        <div class="sixian" style="position: absolute;z-index: 9;top: 100vw;left: -10vw">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1531 820" width="1531" height="820"
                preserveAspectRatio="xMidYMid meet" style="width: 100vw;
    height: 50vw;
    position: relative;
    left: -8vw;
    top: -2vw;">
                <path :class="path1 ? '' : 'path1'" fill="none" stroke="#b6a082" stroke-width=".5"
                    stroke-dashoffset="2400" stroke-dasharray="2400"
                    d=" M1535,-5.5 C1535,-5.5 1519.6600341796875,27.632999420166016 1460.5,46.25 C1389,68.75 1333.5,114 1368.75,171.49899291992188 C1384.4150390625,197.05099487304688 1409.75,211.25 1393.54296875,246.2740020751953 C1383.5350341796875,267.9010009765625 1355.5,277.5 1314,273 C1277.64794921875,269.0580139160156 1233.3709716796875,259.4469909667969 1210.97705078125,289.93499755859375 C1192.75,314.75 1194.75,346 1225.25,402.75 C1239.3709716796875,429.02398681640625 1244.5140380859375,466.25 1226.10400390625,492.1990051269531 C1200.1650390625,528.7620239257812 1162.5439453125,538.1190185546875 1144.6109619140625,540.68701171875 C1111,545.5 1066.5,542.25 1037.75,529 C1015.9860229492188,518.968994140625 1015,501 1023.2109985351562,495.41900634765625 C1033.97802734375,488.10101318359375 1060.260986328125,502.29400634765625 1070.25,516.5 C1081.5,532.5 1087.4849853515625,599.885009765625 1001.5,610.5 C961,615.5 929.0059814453125,606.176025390625 857.3309936523438,596.2139892578125 C799.22998046875,588.1389770507812 699,584.5 655,591 C618.0089721679688,596.4650268554688 583.64599609375,604.9660034179688 552.5189819335938,618.9879760742188 C501.55499267578125,641.9450073242188 457.58599853515625,676.3380126953125 412.5,711.5 C333,773.5 238,808.5 164.5,814 C85.4739990234375,819.9140014648438 15.5,813 -16.5,799">
                </path>
            </svg>
        </div>
        <div class="shuText font1" style="position: absolute;top: 148vw;left: 38.5vw;transition: 1s ease;">
            <div style="top: -14vw;height: 7.5vw;" class="text1Box">
                <span :style="`top:${index * 1}vw`" class="text1Span" v-for="(item, index) in text1" :key="item">
                    {{ item }}
                </span>
            </div>
            <div style="top: -9.5vw;height: 12vw;" class="text1Box">
                <span :style="`top:${index * 1}vw`" class="text1Span" v-for="(item, index) in text2" :key="item">
                    {{ item }}
                </span>
            </div>
        </div>
        <div class="showImgArr"
            style="position: absolute;top: 123vw;left: 58vw;z-index: 9;opacity: 0;filter: blur(.3vw);transition: 2s ease;">
            <img src="@/assets/image/showImgArr.png" style="width: 30vw;" alt="">
        </div>
        <div class="contentBackgroundTwo">
            <img src="@/assets/image/contentBackground2.png" style="width: 100vw;margin-top: -.5vw;height: 44vw;"
                alt="">
        </div>
    </div>
</template>
<style scoped>
.path1 {
    animation: path1 5s forwards;

}

@keyframes path1 {
    0% {
        stroke-dashoffset: 2400;
        stroke-dasharray: 2400;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 2400;
    }
}

.text1Span {
    animation: text1Span .5s forwards;
    position: absolute;
    left: 0;
}

@keyframes text1Span {
    0% {
        opacity: 0;
        filter: blur(.3vw);
        transform: rotateZ(30deg)
    }

    100% {
        filter: blur(0vw);
        opacity: 1;
        transform: rotateZ(0deg)
    }
}

.transform3 {
    animation: show1 1s forwards;
}

@keyframes show1 {
    0% {
        transform: translateX(-100vw);
    }

    80% {
        transform: translateX(5vw);
    }

    100% {
        transform: translateX(0);
    }
}

.enTextSvg path {
    fill: none;
    stroke-width: 5;
    stroke: #ca6a51;
}

.showList {
    scrollbar-width: none;
}

.allLogo svg {
    position: relative;
    top: .6vw;
    margin-left: .7vw;
    width: 2vw
}

.contentBackground img {
    box-shadow: 0vw -1vw 3vw 0 rgba(0, 0, 0, 0.5);
}

.showF {
    /* translate: translateY(5deg) */
    transform: rotateZ(10deg)
}

.showT {
    transform: rotateZ(-10deg)
}

.shuText div {
    display: inline-block;
    position: relative;
    margin-left: 1vw;
    width: 1vw;
    color: #fff;
    text-align: center;
    background: #b6a082;
    transition: 1s cubic-bezier(0, -0.06, .04, .83);
    ;
    /* transform: ; */
    /* 金底色    */
    font-size: .9vw;
    font-weight: 600;
    line-height: 1.5vw;
    /* background: #c2bcbc;  */
    /* 灰底色 */
}

.changeBackgroundWidth:hover>span {
    width: 100%
        /* color: red; */
}

.text_twice p {
    z-index: 2;
    position: relative;
    /* background: #fff; */
    background-size: 1vw 1vw;
}

.text_twice span {
    display: inline-block;
    width: 1vw;
    transition: 1s ease;
    background: #fff;
    height: 1vw;
    position: absolute;
    left: 0;
    z-index: -1;
}

.maxBox img {
    position: relative;
}
</style>
<script>

export default {
    name: 'index_twice',
    props: {
    },
    data() {
        return {
            isShow: false,
            show1: true,
            text1: [
                '生', '活', 'か', 'ら', '、'
            ],//生活から、
            text2: [
                '何', 'か', '物', '足', 'り', 'な', 'い'
            ],//なにか物足りない
            text1Status: true,
            path1: true,
        }
    },
    watch: {
        text1Status(val) {
            // const dom = document.getElementsByClassName('shuText')[0]
            const dom1 = document.getElementsByClassName('text1Box')
            let testData
            if (val == false) {
                // dom.style.transform = 'translate(14vw,20vw)'
                dom1[0].style.color = dom1[1].style.color = 'rgb(108, 122, 122)'
                dom1[0].style.background = dom1[1].style.background = '#fff'
                testData = {
                    f: '生活か仁。',
                    t: '好きなものに囲まれた'
                }
            } else {
                // dom.style.transform = 'translate(0,0)'
                dom1[0].style.color = dom1[1].style.color = '#fff'
                dom1[0].style.background = dom1[1].style.background = '#b6a082'
                testData = {
                    f: '生活を、',
                    t: '何か物足りない'
                }
            }
            const text1Two = testData.f.split('')
            const text2Two = testData.t.split('')
            text1Two.map((item, index) => {
                setTimeout(() => {
                    if (text1Two.length < this.text1.length && !text1Two[this.text1.length - text1Two.length + index]) {
                       this.text1.splice(this.text1.length - text1Two.length + index,1) 
                        console.log(this.text1[this.text1.length-text1Two.length+index]);
                    }
                    if (this.text1[index]) {
                            this.text1[index] = item
                    } else {
                        this.text1.push(item)
                    }
                    this.$forceUpdate()
                }, index * 100);
            })
            text2Two.map((item, index) => {
                setTimeout(() => {
                    if (text2Two.length < this.text2.length && !text2Two[this.text2.length - text2Two.length + index]) {
                       this.text2.splice(this.text2.length - text2Two.length + index,1) 
                    }
                    if (this.text2[index]) {
                            this.text2[index] = item
                    } else {
                        this.text2.push(item)
                    }
                    this.$forceUpdate()
                }, index * 100);
            })
        }
    },
    mounted() {
        const dom = document.getElementsByClassName('indexSvg')[0]
        this.$bus.$on('path1', () => {
            this.path1 = false
        })
        this.$bus.$on('show1Fun', (e) => {
            this.show1 = e
        })
        this.$bus.$on('text1', (e) => {
            this.text1Status = e
        })
        let bottomText = document.getElementsByClassName('bottomText')[0]
        setTimeout(() => {
           
            document.body.style.height = (bottomText.getBoundingClientRect().top + bottomText.offsetHeight) + 'px'
        }, dom.style.display == 'none' ? 1 : 6000)
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        })
        setTimeout(() => {
            this.isShow = true
        }, 4000);
    },
    methods: {
    }
}
</script>
