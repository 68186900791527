<template>
    <div style="width: 100vw;height: 300vh;background: #fff5e7; color:#6a615b;">
        <div class="topMubu">
            <div class="leftmubu"></div>
            <div class="rightmubu"></div>
        </div>
        <!-- <el-input v-model="columnNum"></el-input> -->
        <img src="@/assets/image/shopLogo.png" alt="" class="shopLogo">
        <div class="shopBook">
            <img src="@/assets/image/shopBook.png" style="width: 9vw;
    transform: rotateZ(-21deg);
    position: relative;
    left: -9vw;" alt="">
            <img src="@/assets/image/handleft.png" class="handleft" style="width: 5vw;
    position: absolute;
    transition: .5s ease;
    left: -3vw;
    top: 2vw;" alt="">
            <img src="@/assets/image/handright.png" class="handright"
                style="width: 4vw;position: absolute;top: 4vw;left: 0vw;transition: .5s ease;" alt="">
        </div>
        <div class="door" @click="$router.push('/about_agreement')">
            <img src="@/assets/image/door.png" alt="" class="doorImgShop" width="100%">
            <!-- <div class="doorYinziShop"></div> -->
            <img src="@/assets/image/doorLine.png" class="doorYinziShop" alt="">
            <div class="doorLineShop"></div>
        </div>
        <p class="form font1">Home > About > CIRCUS DOLL > Shops > Products </p>
        <div class="goodsList">
            <div class="goods" v-for="item in 9" :key="item"
                :style="`width:${itemWidth}vw;margin:${5 - (columnNum)}vw;height:${itemWidth + 3 * columnNum}vw`">
                <div :style="`width:${itemWidth}vw;height:${itemWidth}vw;border-radius: .5vw;
                `">
                    <div class="tag" :style="`background:${item == 1 ? '#e18871' : '#e1cfa5'}`">
                        <p style="margin: .3vw;">{{ item == 1 ? 'HOT' : 'NEW' }}</p>
                    </div>
                    <el-image :src="autoImg" class="goodsImg" fit="cover"></el-image>
                    <el-image :src="changeImg" @click="openMenBan()" @mouseleave="leaveImg(item)"
                        @mouseover="onImg(item)" class="goodsImg2"></el-image>
                </div>
                <div class="mark font1" :style="`width:${itemWidth}vw;height:8vw`">
                    <div
                        style="font-size: .6vw;margin:1vw .5vw;overflow-wrap: break-word;word-break: break-all;line-height: 1.5vw;">
                        测试大家开/始的夸奖哈数/そんな思いを含/test the text/そんな思いを含</div>
                    <button v-if="item == 1" class="detilBtn" @click="detailFun(item)"
                        :style="`padding: ${2 / columnNum * .7}vw ${2 / columnNum * 2.5}vw;border: none;border-radius: .3vw;float: right;font-size:.7vw;transition:background 1s ease;`">
                        <!-- <img src="@/assets/image/deteilButton.png" alt="" class="buttonBackground"> -->
                        <span style="position: relative;z-index: 9;">詳細を見る</span>
                    </button>
                    <p style="font-size: .6vw;font-weight: 600;margin-left:0;position: absolute">{{
                        item == 1 ? '￥18,800' : '￥—' }}</p>
                    <p class="font3" style="position: absolute;margin:2vw 1vw;font-size: 1vw;color: red;letter-spacing: .1vw;"
                        v-if="item !== 1">SOLD OUT
                    </p>
                </div>
            </div>
        </div>
        <div class="more">
            <img src="@/assets/image/().png" width="100%" alt="">
            <span style="font-size: .5vw;
                position: relative;
                top: -2.5vw;
                font-weight: 600;
                color: #dc3514;">もっと見る</span>
        </div>
        <index_bottom style="position: relative;top: 15vw" />
    </div>
</template>
<style scoped>
.doorLineShop{
    width: 0vw;
    height: 3vw;
    background: #fff;
    top: -.3vw;
    position: relative;
    left: -.7vw;
    transform: skewx(-32deg) rotateZ(180deg);
    mask-image: url('@/assets/image/door.png');
    mask-size: 4.77vw 3vw;
    transition: 1s;
}
.doorImgShop{
    transform-origin: right;
    transform: skewY(0deg);
    z-index: 2;
    position: relative;
    transition: 1s ease;
}
.door:hover>.doorImgShop{
    transform: skewY(-5deg) rotateY(28deg);
}
.door:hover>.doorLineShop{
    width: 1vw;
}
.doorYinziShop{
    width: 5vw;
    height: 7.5vw;
    position: absolute;
    z-index: 1;
    top: 0;
    filter: blur(.3vw) drop-shadow(0 0 1vw rgba(0,0,0,.5));
    left: 0;
}
.door {
    width: 5vw;
    z-index: 9999;
    position: absolute;
    right: 10vw;
    top: 101vw;

}

.shopBook {
    position: absolute;
    /* transform: */
    top: 65vw;
    left: 12vw;
}

.tag {
    mask-image: url('@/assets/image/tag.png');
    mask-size: 2.55vw 3.4vw;
    width: 2.55vw;
    position: absolute;
    z-index: 9;
    height: 3.4vw;
    left: -1vw;
    top: -1vw;
    color: #fff;
    font-size: .7vw;
    line-height: 2vw;
}

.more {
    width: 10vw;
    position: relative;
    left: 45vw;
    top: 10vw;
    text-align: center;
}

/* .detilBtn:hover>img {
    opacity: 1;
} */

.detilBtn{
    background-image:none;
    background-color: #e2d2ab;
}
.detilBtn:hover{
    background-image:url('@/assets/image/deteilButton.png');
    background-size: 100% 100%;
    color: red;
}

.detilBtn:hover>span {
    border-bottom: red .1vw solid;
}

.buttonBackground {
    z-index: 8;
    opacity: 0;
    cursor: pointer;
    transition: 1s ease;
    position: absolute;
    float: right;
    right: -.1vw;
    width: 9.5vw;
    top: 24vw;
}

.goodsImg {
    height: 100%;
    width: 100%;
    box-shadow: 0vw .1vw 1vw rgba(0, 0, 0, .5);
    position: relative;
    left: 50%;
    border-radius: .5vw;
    transform: translate(-50%, 0);
    transition: .2s ease;
    opacity: 1;
}

.goodsImg2 {
    height: 100%;
    width: 100%;
    opacity: 0;
    box-shadow: 0vw .1vw 1vw rgba(0, 0, 0, .5);
    position: relative;
    left: 50%;
    border-radius: .5vw;
    transform: translate(-50%, 0);
    transition: .2s ease;
    top: -101%;
}

.goods {
    position: relative;
    display: inline-block;
}

.goodsList {
    width: 50vw;
    left: 25vw;
    height: 63vw;
    overflow: hidden;
    position: relative;
    top: 5vw;
}

.form {
    position: relative;
    color: #fe5126;
    left: 10vw;
    font-size: .6vw;
    font-weight: 600;
    top: -3vw;
}

.shopLogo {
    width: 3vw;
    left: 48.5vw;
    position: relative;
    top: -7vw;
}

.topMubu {
    position: relative;
    width: 100vw;
}

.leftmubu,
.rightmubu {
    width: 50vw;
    height: 12vw;
    position: relative;
    top: -5vw;
    background-color: #ca482a;
    display: inline-block;
    transform: skewY(-7deg);
}

.rightmubu {
    transform: skewY(7deg);
}
</style>
<script>
import index_bottom from './index_bottom.vue'

export default {
    name: 'shop_indexName',
    components: {
        index_bottom
    },
    props: {
    },
    data() {
        return {
            columnNum: 0,
            itemWidth: 0,
            changeImg: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            autoImg: 'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
            isSrc: true,
            scrollDisabled: false,
            isScroll: true,
        }
    },
    watch: {
        columnNum(val) {
            this.itemWidth = (50 / val - (5 - (val)) * 2)*.85
        }
    },
    mounted() {
        this.$bus.$on('circusdollScroll', e => {
            this.scrollFun(e)
        })
        this.$bus.$on('closeShop', () => {
            const dom = document.getElementById('menban')
            dom.style.opacity = '0'
            setTimeout(() => {
                dom.style.display = 'none'
            }, 500);
            this.isScroll = true
        })
        this.columnNum = 2
        document.body.style.height = '255vh'
        window.scrollTo({
            top: 0,
            behavior: 'auto' // 平滑滚动
        });
    },
    methods: {
        scrollFun(e) {
            const dom = document.getElementsByClassName('shopBook')[0]
            const handleft = document.getElementsByClassName('handleft')[0]
            const handright = document.getElementsByClassName('handright')[0]
            // console.log(this.getElementDistanceToBottom(dom));
            if (this.getElementDistanceToBottom(dom) > 0) {
                console.log(e/100,Math.pow(2, e/100));
                handright.style.transform = ` translateX(${(e/400)}vw) translateY(-${e/400}vw)`
                handleft.style.transform = ` translateX(-${(e/400)}vw) translateY(${e/400}vw)`
            }
        },
        getElementDistanceToBottom(element) {
            var rect = element.getBoundingClientRect();
            var height = window.innerHeight || document.documentElement.clientHeight;
            var distance = height - rect.bottom;
            return distance;
        },
        detailFun(data) {
            this.$router.push({
                path: '/shop_details',
                query: {
                    test: '1',
                    data: data
                }
            })
        },
        onImg(index) {
            const dom = document.getElementsByClassName('goodsImg')[index - 1]
            const dom1 = document.getElementsByClassName('goodsImg2')[index - 1]
            dom.style.opacity = 0
            dom.style.filter = dom1.style.filter = 'blur(.1vw)'
            dom1.style.opacity = 1
            setTimeout(() => {
                dom.style.filter = dom1.style.filter = 'blur(0vw)'
            }, 200);
        },
        leaveImg(index) {
            const dom = document.getElementsByClassName('goodsImg')[index - 1]
            const dom1 = document.getElementsByClassName('goodsImg2')[index - 1]
            dom.style.opacity = 1
            dom.style.filter = dom1.style.filter = 'blur(.1vw)'
            dom1.style.opacity = 0
            setTimeout(() => {
                dom.style.filter = dom1.style.filter = 'blur(0vw)'
            }, 200);
        },
        openMenBan() {
            // this.$bus.$emit('isScroll', false)
            this.isScroll = false
            this.$nextTick(() => {
                const dom = document.getElementById('menban')
                dom.style.display = 'block'
                setTimeout(() => {
                    dom.style.opacity = '1'
                }, 1);
                this.$forceUpdate()
            })
        }
    }
}
</script>
