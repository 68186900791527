<template>
    <div style="transform-style: preserve-3d;
    perspective: 90vw;" class="box5 pageBox">
        <div class="rotateBox5">
            <!-- <div class="backFirst">
                <img src="@/assets/image/backFirst.png" style="width: 5vw;" alt="" @click.stop="backFirst()"
                    class="backFirstImg">
            </div> -->
        </div>
        <div class="rotateBoxBack5" style="display: none;"></div>
    </div>
</template>
<style scoped>
.rotateBox5 {
    width: 100vw;
    height: 100vh;
    left: 0vw;
    top: 0vw;
    background: #E1DBD4;
    z-index: 6;
    position: absolute;
    transform-origin: left;
    transition: 1s cubic-bezier(.66,.7,1,1);
    backface-visibility: hidden;
}

/* .rotateBoxBackFive{
    background: #e1dbd4;
    width: 50vw;
    height: 100vh;
    position: absolute;
    transform-origin: left;
    z-index: 6;
    transition: 1s ease;
} */
/* .rotateBack{ */
/* transform: rotateY(180deg); */
/* width: 50vw; */
/* overflow: hidden; */
/* } */
</style>
<script>
export default {
    props: {
    },
    data() {
        return {
            lastImgNum: [1, 3, 2, 5, 3, 1, 5, 3, 3, 1, 2],
            isHover: false,
            imgList: {
                '0_0': 'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                '1_0': "https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500",
                "1_1": "https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005",
                "1_2": "https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832",
                "2_0": "https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149",
                "2_1": "https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500",
                "3_0": "https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005",
                "3_1": "https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500",
                "3_2": "https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832",
                "3_3": "https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149",
                "3_4": "https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500",
            }
        }
    },
    mounted() {
        // this.$bus.$on('test',()=>{
        //     // console.log(e);
        //     const dom = document.getElementsByClassName('FiveBox')[0]
        // })
        // const dom = document.getElementsByClassName('FiveBox')[0]
        // this.$bus.$on('fourClick',()=>{
        // })
        const dom = document.getElementsByClassName('romveX')[0]
        this.$bus.$on('noShowLastImage', () => {
            dom.style.transform = 'translateX(0)'
        })
        window.scrollTo({
            top: 0,
            behavior: 'auto' // 平滑滚动
        });
    },
    methods: {
        fiveClick() {
            const dom = document.getElementsByClassName('rotateBoxFive')[0]
            const dom1 = document.getElementsByClassName('rotateBoxBackFive')[0]
            dom.style.transform = 'rotateY(180deg)'
            dom1.style.transform = 'rotateY(180deg)'
        },
        overImg(index, indexT) {
            const dom = document.getElementsByClassName('minImg' + index + '_' + indexT)[0]
            dom.style.width = '7vw'
            // dom.style.height = '7vw'
            dom.style.marginLeft = '-1vw'
            dom.style.transition = '.5s ease'
            dom.style.transform = 'scale(1.05)'
            dom.style.filter = 'blur(.05vw)'
            setTimeout(() => {
                dom.style.transform = 'scale(1)'
                dom.style.filter = 'blur(0vw)'
            }, 500);
        },
        outImg(index, indexT) {
            const dom = document.getElementsByClassName('minImg' + index + '_' + indexT)[0]
            dom.style.width = '5vw'
            dom.style.filter = 'blur(0vw)'
            dom.style.marginLeft = '0vw'
            dom.style.transition = '1.3s ease'
            dom.style.transform = 'scale(.95)'
            setTimeout(() => {
                dom.style.transform = 'scale(1)'
            }, 700);
        },
    }
}
</script>
