const contData = {
    conterText: [
        '2016年　ビリビリ動画で契約歌手として活動開始',
        '2019年　手作り作品を作り始め、ハンドメイド作家として活動',
        '2023年　日本語を勉強し、日本留学へ。同年中に日本語能力試験最上級取得',
        '2024年　通訳として活動する傍ら、CIRCUS DOLLを創立'
    ],
    leftTextContent: [
        'こんにちは、',
        '自分探しているnagiです。',

        '■「今さら？」と思われるかもしれませんが、実際、言語学校の先生にも「遅れた青春期だな」と言われたことがあります（笑）。 ',
     
        '■高校時代は、いじめや孤独、不安でいっぱいの日々を過ごしていました。',
        '外に出るすら怖くて、ただ時間だけが過ぎていく青春で、気づけば自分の人生だけが前に進んでいないように思えました。',
    

        '■あの頃は、“何かを選ぶ”という余裕すらなく、ただ毎日をやり過ごしていました。',

        '■でもある日、「このままでいいのかな？」と自分に問いかけました。',
        'それがきっかけで、少しずつ自分の世界を広げるために動き始めました。',

        '■歌うこと、手を動かして何かを作ること、そして新しい言語を学ぶこと。',
        'やってみると不思議なもので、少しずつ人生が動き出した気がします。',
       

        '■あれからずいぶん経ち、理想の自分にはまだ程遠いけれど、少しだけ誇れる自分にはなれたかもしれません。',
     

        '■遅れて動き始めた人生の時計も、雨の日で立ち止まった分まで、じっくり楽しみながら、',
        '自分のペースで一歩ずつ進んでいきたいなと思いました。',

        "■Nagi’s Choiceは、そんな自分自身を知る旅のひとつです。",
        '選ぶものも、作るものも、どれも心から「好きだ」と思えるものを大切にしたい。',

        '■今の自分が、未来の自分も、',
        '「あれは気持ちいい選択だった」と思えるように。'
    ],
    backConterText: [
        '真っ白な生地から、一輪の花になるまで、',
        '書く、切る、染める、形を作る、組み立てるなど、たくさんの工程を経ているます。',
        '生み出していく中で感じたのは、',

        '「丁寧に時間をかけて作ることで、物に感情や物語が宿る」ということ。',
        'こうした経験が、私の創作の土台となっています。',
    ],
    backBorderText: [
        '世間の価値観に流されることなく、',
        '本当に自分が好きなモノを作ってみたい、',

        'そんなコンセプトでNagi’s Choiceは始まりました。',

        'あなたのお気に入りが見つかるよう、',
        '「自分が好き」と思えるようなブランドづくりを目指したいと思います。',
    ]
}
export default contData