<template>
    <div style="background: #eae6d9;width: 50vw;height: 100vh;z-index: -1;overflow: hidden;
    " class="pageBox box1">
        <shadow_name ref="shadow_name" :isLeft="true" />
        <div class="nameCard" style="position: absolute;width: 22vw;left: 22vw;top: 50vh;transform:translateY(-50%)">
            <div class="lunaCard card" @click="$bus.$emit('author','luna')">
                <img src="@/assets/image/lunaCard.png" style="width: 18vw;position: absolute;left: -.4vw;top: -.5vw;" alt="">
            </div>
            <div class="nagiCard card" style="margin-top: 6vw;" @click="$bus.$emit('author','nagi')">
                <img src="@/assets/image/nagiCard.png" style="width: 18vw;position: absolute;left: -.4vw;top: -.5vw;" alt="">
            </div>
        </div>

    </div>
</template>
<style scoped>
.card:hover{
    transform: scale(1.05)
}
.nagiCard,
.lunaCard {
    width: 13.7vw;
    position: relative;
    height: 5.4vw;
    box-shadow: .1vw .1vw 1vw rgba(0,0,0,0.5);
    margin-top: 3vw;
    transition: 1s ease;
    /* background: #aba097; */
    /* mask-image: url('@/assets/image/door.png');
    mask-repeat: no-repeat; */
}
img {
}
</style>
<script>
import shadow_name from '../../components/shadow.vue'
export default {
    components: {
        shadow_name
    },
    props: {
    },
    data() {
        return {
        }
    },
    mounted() {

        window.scrollTo({
            top: 0,
            behavior: 'auto' // 平滑滚动
        });
    },
    methods: {
    }
}
</script>