<template>
    <div style="position: relative;color: #89b1ad;font-size: .5vw; width: 85%;
    margin-left: 7.5%;
    ">
        <div>
            <div class="fourPageTitle" style="font-size: .8vw;margin-top: 3vw">
                いいね履歴
            </div>
            <div style="overflow: scroll;height: 33vw;">
                <div class="goodsList">
                    <div class="goodItem" v-for="item in likeGoods" :key="item">
                        <el-image style="
                 width: 5vw;
                 margin-left: .5vw;
                 box-shadow: 0 0 .5vw rgba(0,0,0,.3);
    height: 5vw;" fit="cover" :src="item.url"></el-image>
                        <p style="margin: 0;width: 5vw;margin-left: .5vw">{{ item.name }}</p>
                        <p style="margin: 0;color: red;width: 5vw;margin-left: .5vw"
                            @click="item.isLove = !item.isLove">
                            {{ item.isLove ? '♥' : '♡' }}
                        </p>
                    </div>
                </div>
                <div style="width: 100%;height: 1vw; 
    text-align: center;
    border-bottom: .1vw solid rgba(101, 109, 108, .3);
                ;">
                    <p class="moreItem" @click="moreFun(0)">もっと見るv</p>
                </div>
                <div class="worksList"
                    style="margin-top: 2vw;width: 100%;height: 9.3vw;overflow: hidden;transition: 1s ease">
                    <div class="worksTitle" style="margin: -1vw 0 1vw;">
                        Gallery
                    </div>
                    <div class="worksItem" v-for="item in worksLike" :key="item">
                        <el-image style="
                        width: 5vw;height: 8vw;
                        margin-left: .5vw;
                        " fit="cover" :src="item.url">
                        </el-image>
                        <p style="margin: 0;color: red;width: 5vw;margin-left: .5vw;position: absolute;top: 8vw;"
                            @click="item.isLove = !item.isLove">
                            {{ item.isLove ? '♥' : '♡' }}
                        </p>
                    </div>
                </div>
                <div style="width: 100%;height: 1vw; 
    text-align: center;">
                    <p class="moreItem" @click="moreFun(1)" >もっと見るv</p>
                </div>
                <button style="
                        font-size: .5vw;
                        border:none;
                        border-radius: .2vw;
                        padding: .3vw 2vw;
                        color: #fff;
                        background: #849d9b;
                        position: relative;
                        top: 3vw;
                    " @click="$emit('clickItem', 0)">マイページへ</button>
            </div>
        </div>
    </div>
</template>
<style scoped>
.worksItem {
    mask-image: url('@/assets/image/mask.png');
    width: 6vw;
    height: 9vw;
    mask-repeat: no-repeat;
    mask-size: 6vw 9vw;
    margin-left: 1.5vw;
    display: inline-block;
    box-shadow: 0 0 .5vw rgba(0, 0, 0, .3);
    position: relative;
}

.moreItem {
    position: relative;
    /* top: 15.8vw; */
    /* float: right; */
    transition: .5s ease;
    /* right: 1vw; */
}

.goodItem {
    width: 6vw;
    height: 7vw;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    margin-left: 1.5vw;
    margin-top: 0vw;
    /* box-shadow: 0vw 0vw 1vw rgba(0,0,0,.3); */
}

.goodsList {
    margin-top: 2vw;
    width: 100%;
    overflow: hidden;
    height: 14vw;
    transition: 1s ease;
}

.fourPageContent {
    margin-top: 3vw;
    color: #656d6c;
    height: 26vw;
    position: relative;
    overflow: scroll;
}
</style>
<script>

export default {
    props: {
    },
    data() {
        return {
            likeGoods: [
                {
                    isLove: true,
                    name: '12aas/1dasdaasdsdasdasasdas',
                    url: require("@/assets/test/test.png"),
                },
                {
                    isLove: true,
                    name: '12aas/1dasda',
                    url: require("@/assets/test/test2.png")
                },
                {
                    isLove: true,
                    name: '12aas/1dasda',
                    url: require("@/assets/test/test.png")
                },
                {
                    isLove: true,
                    name: '12aas/1dasda',
                    url: require("@/assets/test/test.png")
                },
                {
                    isLove: true,
                    name: '12aas/1dasda',
                    url: require("@/assets/test/test.png")
                },
                {
                    isLove: true,
                    name: '12aas/1dasda',
                    url: require("@/assets/test/test.png")
                },
                {
                    isLove: true,
                    name: '12aas/1dasda',
                    url: require("@/assets/test/test.png")
                },
            ],
            worksLike: [
                { isLove: true, url: require('@/assets/test/test4.jpg') },
                { isLove: true, url: require('@/assets/test/test4.jpg') },
                { isLove: true, url: require('@/assets/test/test4.jpg') },
                { isLove: true, url: require('@/assets/test/test4.jpg') },
            ]
        }
    },
    mounted() {

    },
    methods: {
        moreFun(val) {
            const dom1 = document.getElementsByClassName('moreItem')
            const dom2 = document.getElementsByClassName('goodsList')[0]
            const dom3 = document.getElementsByClassName('worksList')[0]
            let heightNum
            if (val == 0) {
                if (this.likeGoods.length % 3 > 0) {
                    heightNum = Math.floor(this.likeGoods.length / 3) + 1
                } else {
                    heightNum = Math.floor(this.likeGoods.length / 3)
                }
                dom2.style.height = `${heightNum * 7}vw`
                dom3.style.height = '9.3vw'
            } else {
                if (this.worksLike.length % 3 > 0) {
                    heightNum = Math.floor(this.worksLike.length / 3) + 1
                } else {
                    heightNum = Math.floor(this.worksLike.length / 3)
                }
                dom3.style.height = `${heightNum * 9.3}vw`
                dom2.style.height = '14vw'
            }
            Object.values(dom1).map((item, index) => {
                if (index == val) {
                    item.style.opacity = '0'
                    setTimeout(() => {
                        item.style.display = 'none'
                    }, 500);
                } else {
                    item.style.display = 'block'
                    setTimeout(() => {
                        item.style.opacity = '1'
                    }, 1);
                }
            })
        }
    }
}
</script>