<template>
    <div style="position: relative;color: #89b1ad;font-size: .6vw; width: 85%;
    margin-left: 7.5%;
    ">
        <div>
            <div class="threePageTitle" style="font-size: .8vw;margin-top: 3vw">
                ご注文履歴
            </div>
            <div class="threePageContent">
                <div v-if="status == 0" class="all">
                    <div style="border-bottom: .1vw solid rgba(101, 109, 108, .3);position: relative;"
                        v-for="item in historyList" :key="item">
                        <!-- {{ }} -->
                        <p><span>{{ item.date }}</span>ご注文日時</p>
                        <p><span>{{ item.designation }}</span>ご注文番号</p>
                        <p><span>{{ item.pirce }}</span>金额</p>
                        <p><span>{{ item.payFun }}</span>お支払い方法</p>
                        <p><span>{{
                            item.status == 0 ? '未支付' : (item.status == 1 ? "待支付" : "已支付")
                                }}</span>ステータス</p>
                        <button style="
                        position: absolute;
                        left: 14vw;
                        top: 4vw;
                        font-size: .5vw;
                        border: .1vw solid rgba(101, 109, 108, .3);
                        border-radius: .2vw;
                        padding: .3vw 2vw;
                        color: #656d6c;
                        background: #fff;
                    " @click="detailFun(item)">ご注文内容</button>
                    </div>
                </div>
                <div v-else>
                    <div class="dateil">
                        <p><span>{{ currtData.date }}</span>ご注文日時</p>
                        <p><span>{{ currtData.designation }}</span>ご注文番号</p>
                        <p><span>{{ currtData.pirce }}</span>金额</p>
                        <p><span>{{ currtData.payFun }}</span>お支払い方法</p>
                        <p><span>{{
                            currtData.status == 0 ? '未支付' : (currtData.status == 1 ? "待支付" : "已支付")
                                }}</span>ステータス</p>
                        <p><span>{{ currtData.logistics+'>' }}<a href="https://www.baidu.com" target="_blank">www.baidu.com</a></span>配送伝票番号</p>
                    </div>
                    <div class="titleThreePageT" style="font-size: .8vw;margin-top: 1vw;color:#89b1ad">
                        ご注文商品
                    </div>
                    <div class="goodsDetail">
                        <div v-for="item in currtData.goods" :key="item"
                        style="border-top: .1vw solid rgba(101, 109, 108, .3);
                        line-height: .5vw;
                        padding: .5vw 0;
                        "
                        >
                        <!-- {{ item }} -->
                        <p><span>品番:</span>{{ item.nameId }}</p>
                        <p><span>品名:</span>{{ item.name }}</p>
                        <p><span>サイズ:</span>{{ !item.sku.size?'free':item.sku.size }}</p>
                        <p><span>カラー:</span>{{ !item.sku.color?'free':item.sku.color }}</p>
                        <p><span>単価:</span>{{ item.pirce }}</p>
                        <p><span>数量:</span>{{ item.num }}</p>
                        <p><span>価格:</span>{{ item.num*item.pirce }}</p>
                        </div>
                    </div>
                    <p class="bottomSum" >小计<span>{{ "￥"+sumAll() }}</span></p>
                    <p class="bottomSum">送料<span>{{ "￥"+'1000' }}</span></p>
                    <p class="bottomSum">合计<span>{{ "￥"+sumAll()+1000 }}</span></p>
                    <div class="userData">
                    <div class="userTitle">
                        ご配送先
                    </div>
                    <div class="userDataDteail">
                        <p>{{ user.name }}</p>
                        <p>{{ user.firstCode+'--'+user.lastCode }}</p>
                        <p>{{ user.city+user.county }}</p>
                        <p>{{ user.phone }}</p>
                    </div>
                    <div>
                        <p>備考</p>
                        <p>{{ currtData.mark }}</p>
                    </div>
                </div>
                </div>
               
            </div>
            <button style="
                        font-size: .5vw;
                        border:none;
                        border-radius: .2vw;
                        padding: .3vw 2vw;
                        color: #fff;
                        background: #849d9b;
                    " @click="$emit('clickItem', 0)">マイページへ</button>
        </div>
    </div>
</template>
<style scoped>
.bottomSum{
    border-top: .1vw solid rgba(101, 109, 108, .3);
    text-align: right;
    padding: .5vw 0 0;
}
.threePageContent {
    margin-top: 1vw;
    color: #656d6c;
    height: 26vw;
    position: relative;
    overflow: scroll;
}

.all span,.dateil span {
    margin-left: 25%;
    position: absolute;
    display: inline-block;
}
</style>
<script>

export default {
    props: {
    },
    data() {
        return {
            historyList: [
                {
                    date: '2024/09/05 14:52:30',
                    designation: "dasdassa",
                    pirce: "18800",
                    mark:"备注",
                    payFun: 'カード',
                    status: 0,
                    goods:[
                    {
                        nameId:'ajhdkh',
                        name:'asdasd/后阿达大大/1234',
                        sku:{},
                        pirce:'18800',
                        num:'2',
                    },
                    {
                        nameId:'ajhdkh',
                        name:'asdasd/后阿达大大/1234',
                        sku:{
                            color:'绿',
                            size:'s'
                        },
                        pirce:'900',
                        num:'1',
                    }
                    ]
                },
             
            ],
            user:{
                name:'test',
                firstCode:'0371',
                lastCode:'12313333',
                city:'东京市',
                county:'撒旦艰苦会卡死',
                phone:'345321344',
            },
            status: 0,
            currtData:{},
        }
    },
    mounted() {

    },
    methods: {
        detailFun(e){
            this.currtData = e
            this.status = 1
        },
        sumAll(){
            let allSum = 0
            this.currtData.goods.map((item)=>{
                allSum+=  item.pirce*item.num
            })
            return allSum
        }
    }
}
</script>