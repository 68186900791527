<template>
  <div>
    <div class="bottomContent">
      <!-- test -->
      <!-- <img src="@/assets/image/aboutBottomBackground.png" class="background" alt=""> -->
      <div class="text_bottom" style="z-index: 2;position: relative;">
        <div style="text-align: left;z-index: 2;position: absolute;" class="font4">
          <p class="left"><span class="bottomTitle">❚ お買い物ガイド</span><br><span class="font1">・ご購入時のお願い</span><br><span
              class="font1">・お支払いについて</span></p>
          <p class="left"><span class="bottomTitle">❚ よくある質問</span><br><span class="font1">・配送／送料について</span><br><span
              class="font1">・返品・交換について</span></p>
          <p class="left"><span class="bottomTitle">❚ お問い合わせ</span><br><span class="font1">・ご相談・コラボなど</span><br><span
              class="font1">・作品の委託販売について</span></p>
        </div>
        <div class="font1" style="text-align: left;position: absolute;z-index: 2;top: 5vw;">
          <p class="left"><span class="bottomTitle">❚ 特定商取引法に基づく表記</span><br>プライバシーポリシー</p>
          <p class="left" style="margin-left: 1.5vw;top: 1.5vw;"><span class="bottomTitle">❚ SNS</span>
            <br><svg t="1728122333493" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="8719">
              <path
                d="M256 85.333333h512a170.666667 170.666667 0 0 1 170.666667 170.666667v512a170.666667 170.666667 0 0 1-170.666667 170.666667H256a170.666667 170.666667 0 0 1-170.666667-170.666667V256a170.666667 170.666667 0 0 1 170.666667-170.666667z m0 85.333334a85.333333 85.333333 0 0 0-85.333333 85.333333v512a85.333333 85.333333 0 0 0 85.333333 85.333333h512a85.333333 85.333333 0 0 0 85.333333-85.333333V256a85.333333 85.333333 0 0 0-85.333333-85.333333H256z"
                p-id="8720" fill="#6c7a7a"></path>
              <path
                d="M512 682.666667a170.666667 170.666667 0 1 0 0-341.333334 170.666667 170.666667 0 0 0 0 341.333334z m0-85.333334a85.333333 85.333333 0 1 1 0-170.666666 85.333333 85.333333 0 0 1 0 170.666666z"
                p-id="8721" fill="#6c7a7a"></path>
              <path d="M746.666667 277.333333m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z" p-id="8722" fill="#6c7a7a">
              </path>
            </svg>&nbsp;&nbsp;&nbsp;@Nagis_inside&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;@Narusparkle&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;@CircusDo11
            <br>
            <svg t="1728122483914" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="10938">
              <path
                d="M683.776 256h91.605333l-200.106666 216.874667L810.666667 768h-184.32l-144.384-178.986667L316.8 768H225.109333l214.058667-231.978667L213.333333 256h189.013334l130.474666 163.584L683.776 256z m-32.170667 460.032h50.773334L374.741333 305.237333H320.298667l331.306666 410.794667z"
                fill="#6c7a7a" p-id="10939"></path>
            </svg>&nbsp;&nbsp;&nbsp;@Nagis_inside&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;@CircusDo11
          </p>
        </div>
        <div class="font1" style="display: inline-block;font-size: .7vw;z-index: 2;position:absolute;left: 70vw;">
          <p class="font4">❚ 会員登録</p>
          <p style="text-align: left;"><span class="bottomTitle font4">❚ 買い物する
            </span><br>・SHOPに戻る<br>・Buy
            me a coffe</p>
          <p class="font4">❚ 日本語</p>
        </div>
      </div>
      <img style="line" src="@/assets/image/line.png" alt="" class="line">
      <p class="copyright bdx">©Copyright 2024 Nagis Choice All Right Reserved Privacy Policy</p>
    </div>
  </div>
</template>
<script>

export default {
  name: 'index_bottomName',
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
    // document.body.style.height = '105vw'
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'auto' // 平滑滚动
    // });
  },
  methods: {
  }
}
</script>
<style scoped>
.bottomContent {
  background-image: url('@/assets/image/aboutBottomBackground.png');
  height: 25vw;
  width: 100vw;
  background-size: 100vw 25vw;
  background-position: 0 -2vw;
  position: relative;
  color: rgba(0,0,0,.5)
}

.copyright {
  font-size: .65vw;
  text-align: center;
  position: relative;
  z-index: 2;
  top: 10vw;
}

.icon {
  width: 1vw;
}

.line {
  z-index: 4;
  position: relative;
  width: 10vw;
  top: 0vw;
  left: -1vw;
}

/* .background {
  position: relative;
  top: -13vw;
  z-index: 1;
} */

.left {
  display: inline-block;
  left: 5vw;
  margin-left: 5vw;
  position: relative;
  font-size: .7vw;
}

.text_bottom {
  transform: rotateZ(6deg);
  top: 10vw;
}
</style>
