<template>
    <div class="box2 pageBox">
        <div class="rotateBox2" @click="twoClick(1)">
            <shadow_name style="left: -50vw;" ref="shadow_name" :isLeft="false" />
            <div v-if="author == ''">
                <div>
                    <img src="@/assets/image/formImage.png" alt="" class="formImageBack">
                </div>
            </div>
            <div v-if="author == 'nagi'" style="background: rgb(202,199,180);">
               <div style="position: absolute;text-align: center;width: 50vw;top: 27vw;">
                <p class="font4" style="
                z-index: 9;color: #fff;font-size: .7vw;position: relative;
                ">welcome to Nagi’s Gallery</p>
                <scrollAnimte style="transform: scale(.5);z-index: 1;left: -1.5vw;
    top: -1vw;" />
               </div>
                <img src="@/assets/image/scaleNagiCard.png" style="width: 50vw;position: relative;left: 0" alt="">
            </div>
            <div v-if="author == 'luna'" style="background: rgb(167,154,145);">
                <div style="position: absolute;text-align: center;width: 50vw;top: 27vw;">
                <p class="font4" style="
                z-index: 9;color: #fff;font-size: .7vw;position: relative;
                ">welcome to Luna’s Gallery</p>
                <scrollAnimte style="transform: scale(.5);z-index: 1;left: -1.5vw;
    top: -1vw;" />
               </div>
                <img src="@/assets/image/scaleLunaCard.png" style="width: 50vw;position: relative;left: 0" alt="">
            </div>
        </div>
        <div class="rotateBoxBack2" @click="twoClick(0)">
            <div class="rotateBack">
                <shadow_name ref="shadow_name" :isLeft="true" />
                <div class="bar">
                    <img src="@/assets/image/allBar.png" alt="" class="allBar">
                </div>
                <div class="galaryTwoText1 font4"
                    style="top: 1vw;font-size: .3vw;text-align: center;color: #f5edd6;left: 19vw;">
                    <p>The world that's waiting up for me. Through the dark through the door.Through where no one's been
                        before,</p>
                    <p>But it feels like home. They can say, it all sounds crazy. They can say, I've lost my mind. </p>
                    <p>{{ "But I don't care , SO call me crazy. We can live in a world that we design “ <"
                    +"AMillionDreams > "}} </p>
                </div>
                <div class="showImageBox">
                    <el-image :src="url" @click.stop="showScale()" fit="cover" class="showImg">
                    </el-image>
                </div>
                <img src="@/assets/image/niao.png" alt="" style="position: absolute;top: 10vw;width: 3vw;left: 20vw;">
                <div class="galaryTwoText2 font2"
                    style="top: 10vw;font-size: .5vw;left: 27vw;text-align: center;color: #f5edd6;">
                    <p v-for="(item, index) in text2" class="galaryTwoText2P" :key="item"
                        :style="`margin-top:${index == 1 || index == 3 ? '1.5' : '0'}vw;`">
                        {{ item }}
                    </p>
                </div>
                <img src="@/assets/image/lunaBackground.png" alt="" class="lunaBackground">
            </div>
        </div>
    </div>
</template>
<style scoped>
.galaryTwoText2P {
    opacity: 0;
    filter: blur(.3vw);
    transition: 1s ease;
}

.rotateBox2 {
    width: 50vw;
    height: 100vh;
    background: #faf1d8;
    position: absolute;
    z-index: 1;
    transform-origin: left;
    transition: 1s cubic-bezier(.66,.7,1,1);
    overflow: hidden;
    backface-visibility: hidden;
    /* box-shadow: -1vw 0vw 10vw rgba(0,0,0,.5); */
}

.rotateBoxBack2 {
    width: 50vw;
    height: 100vh;
    background: #a6998f;
    position: absolute;
    z-index: 0;
    transform-origin: left;
    transition: 1s cubic-bezier(.66,.7,1,1);
    overflow: hidden;
    box-shadow: 1vw 0 1vw rgba(0, 0, 0, .5);
}

.box2 {
    transform-style: preserve-3d;
    perspective: 90vw;
    position: relative;
}

.showImageBox {
    position: absolute;
    top: 12vw;
    left: 8vw;
    mask-image: url('@/assets/image/mask.png');
    mask-repeat: no-repeat;
    mask-size: 10vw 15vw;
    background-color: #fff;
}

.showImg {
    position: relative;
    display: inline-block;
    transition: .5s ease;
    width: 10vw;
    height: 15vw;
}

.allBar {
    width: 49vw;
}

.lunaBackground {
    width: 20vw;
    position: absolute;
    top: 22vw;
    /* left: 20vw; */
    right: 0;
}

.scroll {
    width: 2vw;
    position: absolute;
    right: 5vw;
    top: 2vw;
}

.formImage {
    width: 40vw;
    position: absolute;
    bottom: 2vw;
    left: 5vw;
}

.formImageBack {
    width: 40vw;
    position: absolute;
    top: 90vh;
    right: 5vw;
}

.rotateBack {
    transform: rotateY(180deg);
}

.galaryTwoText1,
.galaryTwoText2 {
    position: absolute;
}
</style>
<script>
import shadow_name from '../../components/shadow.vue'
import scrollAnimte from '../../components/scrollAnimte.vue'
export default {
    components: {
        shadow_name, scrollAnimte
    },
    props: {
        srcList: {
            type: Array
        }
    },
    data() {
        return {
            url: require('@/assets/test/test.png'),
            index: '',
            author: '',
            text1: '',
            text2: [
                'こんにちは、ルナです。',
                '主にドール服を作っています。',
                '好きな曲は「A Million Dreams」です。',
                'これからもモノづくりしながら',
                '色んな冒険できたら、',
                'もっと大きい世界を見れたらいいなと思ってます。',
            ],
            //   'こんにちは、ルナです。',

            // 主にドール服を作っています。
            // 好きな曲は「A Million Dreams」です。

            // これからもモノづくりしながら
            // 色んな冒険できたら、
            // もっと大きい世界を見れたらいいなと思ってます。
        }
    },
    watch: {
    },
    mounted() {
        this.$bus.$on('author', e => {
            console.log('1');
            this.author = e
        })
        this.$bus.$on('changeImage', (e) => {
            const dom = document.getElementsByClassName('showImg')[0]
            dom.style.filter = 'blur(.3vw)'
            this.index = e.index
            setTimeout(() => {
                this.url = this.srcList[e.index]
            }, 500);
            setTimeout(() => {
                dom.style.filter = 'blur(0vw)'
            }, 1000);
        })
        // this.$bus.$on('changeI')
    },
    methods: {
        twoClick(index) {
            this.$bus.$emit('testChange', index)
        },
        showScale() {
            const data = {
                // show:this.url,
                index: this.index,
                list: [...this.srcList],
            }
            console.log(this.srcList);
            this.$bus.$emit('openImage', data)
            // this.$emit('scaleTure')
        },
    }
}
</script>