import Vue from "vue";
import VueRouter from 'vue-router'

import index_twice from '../view/index_twice.vue'
import contact from '../view/contact.vue'
import about from '../view/about.vue'
import login from '../view/login.vue'
import about_agreement from '../view/about_agreement.vue'
import about_nagi from '../view/about_nagi.vue'
import about_circus from '../view/about_circus.vue'
import galary_index from '../view/galary_index.vue'
import shop_index from '../view/shop_index.vue'
import shop_details from '../view/shop/shop_details.vue'
import payPage from '../components/payPage.vue'
import myPage from '../view/myPage.vue'

Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'hash',
    routes: [
        { path: '/', redirect: '/index_twice' },
        { path: '/index_twice', component: index_twice,name:'index_twice' },
        { path: '/contact', component: contact,name:'contactName'  },
        { path: '/about', component: about,name:'aboutName' },
        { path: '/login', component: login,name:'loginName' },
        { path: '/about_agreement', component: about_agreement,name:'about_agreementName' },
        { path: '/about_nagi', component: about_nagi,name:'about_nagiName' },
        { path: '/about_circus', component: about_circus,name:'about_circusName' },
        { path: '/galary_index', component: galary_index,name:'galary_indexName' },
        { path: '/circusdoll', component: shop_index,name:'shop_indexName' },
        { path: '/shop_details', component: shop_details,name:'shop_detailsName', },
        { path: '/payPage', component: payPage,name:'payPageName', },
        { path: '/CIRCUSDOLL', redirect: '/circusdoll' },
        { path: '/myPage', component: myPage,name:'myPageName' },
    ]
})
export default router

