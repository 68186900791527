import { render, staticRenderFns } from "./about_circus.vue?vue&type=template&id=52e034ba&scoped=true"
import script from "./about_circus.vue?vue&type=script&lang=js"
export * from "./about_circus.vue?vue&type=script&lang=js"
import style0 from "./about_circus.vue?vue&type=style&index=0&id=52e034ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52e034ba",
  null
  
)

export default component.exports