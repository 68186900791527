<template>
    <div class="shell">
        <div class="content">
            <div class="item" v-for="(item, index) in 4" :key="item" @click="clickDepartment(item)">
                <el-row style="width: 3vw;">
                    <svg t="1731429373875" :class="'scrollSvg svgDom' + index" viewBox="0 0 1024 1024" version="1.1"
                        style="width:3vw;height: 2vw;" xmlns="http://www.w3.org/2000/svg" p-id="4242" width="200"
                        height="200">
                        <path
                            d="M512 704c8.288 0 15.776-3.232 21.456-8.4l0.064 0.08 352-320-0.08-0.08c6.448-5.856 10.56-14.208 10.56-23.6a32 32 0 0 0-32-32c-8.288 0-15.76 3.232-21.456 8.4l-0.08-0.08L512 628.752 181.536 328.32l-0.08 0.08A31.776 31.776 0 0 0 160 320a32 32 0 0 0-32 32c0 9.376 4.112 17.744 10.544 23.6l-0.08 0.08 352 320 0.08-0.08c5.68 5.168 13.168 8.4 21.456 8.4z"
                            p-id="4411"></path>
                    </svg>
                </el-row>
            <p style="margin-left: 3vw;color: black">SCROLL</p>
            </div>
        </div>
    </div>
</template>
<style scoped>
.shell {
    position: relative;
    margin: 0;
    color: white;
    perspective: 1000vw;
    transform-origin: center;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vw;
    transform-style: preserve-3d;
}

.item {
    position: absolute;
    width: 3vw !important;
    height: 2vw !important;
    border-radius: 6px;
    background-size: cover;
}


.svgDom1 {
    transform: rotateX(0) translateZ(3vw) rotateX(0);
    opacity: .5;
}


.svgDom2 {
    transform: rotateX(90deg) translateZ(3vw) rotateX(-90deg);
    opacity: .2;
}



.svgDom13 {
    transform: rotateX(180deg) translateZ(3vw) rotateX(-180deg);
    opacity: .2;
}



.svgDom14 {
    transform: rotateX(270deg) translateZ(3vw) rotateX(-270deg);
    opacity: .5;
}

.scrollSvg {
    transition: .1s ease;
}
</style>
<script>

export default {
    components: {
    },
    props: {
    },
    data() {
        return {
            mouse: 0,
        }
    },
    mounted() {
        setInterval(() => {
            this.handleMouseMoveT(400)
        }, 1);
    },
    methods: {
        handleMouseMoveT(event) {
            let dom = document.getElementsByClassName('scrollSvg')
            var mouseY = event
            Object.values(dom).map((item, index) => {
                let str = item.style.transform.split(' ')[1]
                const indexT = str?.indexOf('deg')
                const rotate = (typeof indexT == 'undefined' ? index * 90 : Number(str?.slice(8, indexT)))
                // const widthRotate = rotate < 0 ? (rotate < -180 ? Math.abs(rotate + 360) : rotate) : // rotate为负的情况
                //     (rotate > 180 ? Math.abs(rotate - 360) : rotate) // rotate为正的情况
                item.style.opacity = Math.abs(Math.abs(rotate / 180) - .75)
                item.style.transform = 'translateX(3vw) ' +
                    'rotateX(' + ((rotate + (this.mouse > mouseY ? +mouseY : -mouseY) * .0002) % 360) + 'deg) ' +
                    'translateZ(1vw)  ' +
                    'rotateX(' + (typeof str == 'undefined' ? 0 : -rotate) + 'deg)'
            })
            this.mouse = mouseY
            return
        },
    }
}
</script>