<template>
    <div class="romveX">
        <div style="display: inline-block;width: 7vw;height: 100vh; " v-for="(item, index) in lastImgNum" :key="item">
            <div class="imageLast">
                <img v-for="(itemT, indexT) in item" @mouseover="overImg(index, indexT)"
                    @mouseout="outImg(index, indexT)" :key="itemT" :class="'minImg' + index + '_' + indexT + ' minImg'"
                    :src="imgList[index + '_' + indexT]" />
            </div>
        </div>
    </div>
</template>
<style scoped>
.romveX {
    z-index: -1;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(50vw);
    transition: 1s cubic-bezier(0, -0.06, .04, .83);
}

.imageLast {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.minImgHover {
    animation: test1 .7s forwards;
}

.minImg {
    /* transition: .3s ease; */
    width: 5vw;
    display: block;
    margin-top: 1vw;
    /* : ; */
    /* height: 5vw; */
    background: red;
    transform-origin: 3.5vw 3.5vw;
}
</style>
<script>

export default {
    props: {
    },
    data() {
        return {
            lastImgNum: [1, 3, 2, 5, 3, 1, 5, 3, 3, 1, 2],
            isHover: false,
            imgList: {
                '0_0': 'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                '1_0': "https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500",
                "1_1": "https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005",
                "1_2": "https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832",
                "2_0": "https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149",
                "2_1": "https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500",
                "3_0": "https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005",
                "3_1": "https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500",
                "3_2": "https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832",
                "3_3": "https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149",
                "3_4": "https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500",
            },
        }
    },
    mounted() {

    },
    methods: {
        overImg(index, indexT) {
            const dom = document.getElementsByClassName('minImg' + index + '_' + indexT)[0]
            dom.style.width = '7vw'
            // dom.style.height = '7vw'
            dom.style.marginLeft = '-1vw'
            dom.style.transition = '.5s ease'
            dom.style.transform = 'scale(1.05)'
            dom.style.filter = 'blur(.05vw)'
            setTimeout(() => {
                dom.style.transform = 'scale(1)'
                dom.style.filter = 'blur(0vw)'
            }, 500);
        },
        outImg(index, indexT) {
            const dom = document.getElementsByClassName('minImg' + index + '_' + indexT)[0]
            dom.style.width = '5vw'
            dom.style.filter = 'blur(0vw)'
            dom.style.marginLeft = '0vw'
            dom.style.transition = '1.3s ease'
            dom.style.transform = 'scale(.95)'
            setTimeout(() => {
                dom.style.transform = 'scale(1)'
            }, 700);
        },
    }
}
</script>