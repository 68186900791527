<template>
    <!-- <div>test</div> -->
    <div class="content">
        <img src="@/assets/image/aboutNagiLogo.png" class="logo" alt="">
        <div style="background: #dad7cc;width: 100vw;height: 37vw;">
            <div class="text_about font1">
                <p v-for="(item, index) in textData" :key="item">
                    <span v-if="text['text' + index].indexOf('#') > -1"> {{ text['text' + index].slice(text['text' +
                        index].indexOf('#') + 1, text['text' + index].lastIndexOf('#')) }}</span>
                    {{ text['text' + index].indexOf('#') > -1 ? text['text' + index].slice(text['text' +
                        index].lastIndexOf('#') + 1, text['text' + index].length) : text['text' + index] }}
                </p>
                <p class="font1">静けさの中に隠された可能性や、新しい始まりを象徴しています。</p>
                <img class="linglan" src="@/assets/image/miniLinglan.png" alt="">

            </div>
            <div class="font2 top_text_other">
                <p ><span style="color:#518f8b">Nagi’s choice の始まり</span><span style=" color:#7b7e76">は、布花づくりでした。</span></p>
            </div>
            <div class="tip  font2">
                <div class="test"
                    style="transform: translateY(-10vw);z-index: 2;position: relative;transition: 1s cubic-bezier(0, -0.06, .04, .83);opacity: 0;filter: blur(1vw);">
                    <span class="aboutText">about</span>
                    <span class="aboutText_horizatal">Nagi's Choiceの想い</span>
                    <img src="@/assets/image/NAGI'S_about.png" class="smallLogo" alt="">
                </div>
                <div class="test2"
                    style="z-index: 1;position: relative;transform: translateX(20vw);transition: 2s cubic-bezier(0, -0.06, .04, .83);opacity: 0;filter: blur(.3vw);color: #6a615b;">
                    <!-- <img src="@/assets/image/textBackground.png" class="textBackground" alt=""> -->
                    <div class="textBackground "></div>

                    <div class="font4" style="color: #4a4949;padding-top:2vw;">
                        <p >
                            「心地よい選択をしよう」
                        </p>
                        <p style="margin-top: 2vw;"> 世の中には物が溢れ、私たちは知らず知らずのうちに</p>
                        <p  style="margin-top: 1vw;">さまざまな「選択」を繰り返しています。</p>
                        <p style="margin-top: 2vw;">しかし、その選択は本当に自分が好きなものなのでしょうか</p>
                        <p style="margin-top: 1vw;">それとも、社会的に構築されたモノなんでしょうか。</p>
                        <p >そんな問いを抱いたとき、</p>
                        <p style="margin-top: 2vw;">自分の「好き」を大切にしたいと感じ、</p>
                        <p>Nagi’s Choice を始めました。"</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="font1" style="transform-style: preserve-3d;
    perspective: 100vw;">
            <div style="position: absolute;width: 100vw;height: 44vw;background: #f6efe5;overflow: hidden;transition: 1s cubic-bezier(0, -0.06, .04, .83);z-index: 0;"
                :class="isChange ? 'changeT' : 'change'">
                <img src="@/assets/image/rightXianGao.png" class="xiangao" alt="">
                <div
                    style="position: absolute;background: #f2dad0;width: 100vw;height: 28vw;border-bottom: .1vw solid rgba(190, 189, 181,.2);">
                </div>
                <div class="conterText">
                    <p v-for="item in conterText" :key="item" class="conterTextP">{{ item }}</p>
                </div>
                <div class="leftTextContent">
                    <p v-for="item in leftTextContent" :key="item" class="leftTextContentP"
                        :style="`margin-top:${item.indexOf('■') > -1 ? '2' : '0'}vw`">
                        {{ item.indexOf('■') > -1 ? item.slice(item.indexOf('■') + 1, item.length) : item }}
                    </p>
                </div>
                <div class="rightAllIcon">
                    <p class="pIcon">「まだ知らない自分に会いにいく。」</p>
                    <img src="@/assets/image/NAGI'S_aboutTwo.png" class="tNagi" alt="">
                    <div @click="changePage()" class="hover3">
                        <!-- <img src="@/assets/image/changePage.png" class="changePage" alt=""> -->
                        <div class="changePage"></div>
                        <!-- <p></p> -->
                        <div class="textBox">
                            <div class="changePageText1">自分自身を</div>
                            <div class="line1"></div>
                            <div class="changePageText2">知る旅をしてます</div>
                            <div class="line2"></div>
                            <div class="changePageText3" style="margin: 0 0 1vw">...</div>
                            <div class="changePageText4"></div>
                            <div class="line3"></div>
                        </div>
                    </div>
                </div>
                <img src="@/assets/image/door.png" style="
                width: 6.7vw;
                top: 32.2vw;
                position: absolute;
                left: 76.4vw;
                z-index: 100;
                transform-origin: right;
                transition: 1s ease;" alt="" @mouseenter.stop="hoverDoor()" @mouseleave.stop="hoverDoorFalse()"
                    class="doorImg">
                <!-- <div class="backDoor"></div> -->
                <img src="@/assets/image/doorLine.png" class="backDoor" alt="">
                <div class="backDoorLine"></div>
            </div>
            <div style="position: absolute;width: 100vw;height: 44vw;background: #f0efe2;overflow: hidden;transition: 1s cubic-bezier(0, -0.06, .04, .83);transform: rotateY(180deg);z-index: 1;backface-visibility: hidden;"
                :class="isChange ? 'change' : 'changeT'">
                <div style="width: 100vw;height: 30vw;background: #bebdb5;position: absolute;bottom: 0;"></div>
                <div>
                    <img src="@/assets/image/backTextBackground.png" class="backTextBackground" alt="">
                    <div style="position: absolute;left: 58vw;font-size: 0.7vw;top: 9vw;color: rgba(0,0,0,.5)">
                        <p v-for="item in backBorderText" :key="item">{{ item }}</p>
                    </div>
                </div>
                <div class="backConterText">
                    <p v-for="item in backConterText" :key="item">
                        {{ item }}
                    </p>
                </div>
                <div class="backIcon">
                    <img src="@/assets/image/leftHua.png" alt="" class="leftHua">
                    <div class="font4"
                        style="position: absolute;font-size: 3vw;left: 40vw;top: 4vw;color: #fff;opacity: .3;letter-spacing: .5vw;">
                        NAGI’S CHOICE</div>
                    <div style="position: relative;top: -10vw;left: -12vw;">
                        <span class="backAboutText">about</span>
                        <img src="@/assets/image/NAGI'S_about.png" class="smallLogo" alt="">
                        <p style="color: #5fa29e;font-size: .9vw;position: relative;top: -4vw;left: 19vw">でした。</p>
                    </div>
                    <!-- <img src="@/assets/image/backChangePage.png" alt="" @click="changePage()" class="backChangePage"> -->
                    <div class="changePageBox font2" @click="changePage()">
                        <!-- <img> -->
                        <div class="jiantou backLine"></div>
                        <div class="changePageText4" style="
                       position: relative;
                        top: -15vw;
                        font-size: 0.65vw;
                        color: rgb(255, 255, 255);
                        left: 66vw;
                        width: 10vw;
                        ">
                            <div class="changePageText4" style="margin-left: -3.5vw;">もう少し</div>
                            <div class="line4 backLine"></div>
                            <div class="changePageText4" style="margin-left: -3.5vw;">見ますか？</div>
                            <div class="line5 backLine"></div>
                        </div>
                    </div>
                    <img src="@/assets/image/catIcon.png" alt="" class="catIcon">
                </div>
            </div>
        </div>
        <index_bottom style="position: relative;top: 46vw;" />
    </div>
</template>
<script>
import index_bottom from './index_bottom.vue';
import contData from "@/components/aboutText";

export default {
    name: 'about_nagiName',
    components: {
        index_bottom
    },
    props: {
    },
    data() {
        return {
            textTest: '',
            textData: [
                'Nagi とは、',
                '風がやんで、波が穏やかになることを指す言葉。',
                // "#Nagi's Choice# は、",
                // '気持ちいい選択をする',
                // 'という意味が込められている。'
            ],
            text: {
                text0: '',
                text1: '',
                text2: '',
                text3: '',
                text4: '',
            },
            conterText: contData.conterText,
            leftTextContent: contData.leftTextContent,
            isChange: false,
            backConterText: contData.backConterText,
            backBorderText: contData.backBorderText,
        }
    },
    mounted() {
        this.dazijiFunT()
        const dom = document.getElementsByClassName('linglan')[0]
        const test = document.getElementsByClassName('test')[0]
        const test2 = document.getElementsByClassName('test2')[0]
        // const tipShow = document.getElementsByClassName('tipShow')[0]
        const conterTextPArr = document.getElementsByClassName('conterTextP')
        document.body.style.height = '105vw'
        window.scrollTo({
            top: 0,
            behavior: 'auto' // 平滑滚动
        });
        this.textTest = this.textData.join('')
        setTimeout(() => {
            dom.style.opacity = '1'
            dom.style.scale = '1.5'
        }, 40 * this.textTest.length);
        setTimeout(() => {
            dom.style.scale = '1'
            test.style.transform = 'translateY(0vw)'
            test2.style.transform = 'translateX(0vw)'
            test2.style.filter = test.style.filter = 'blur(0)'
            test2.style.opacity = test.style.opacity = '1'
        }, 40 * this.textTest.length + 500);
        setTimeout(() => {
            Object.values(conterTextPArr).map((item, index) => {
                setTimeout(() => {
                    item.style.opacity = 1
                    item.style.filter = 'blur(0)'
                }, index * 500)
            })
        }, 40 * this.textTest.length + 1500);

    },
    methods: {
        dazijiFunT() {
            console.log('none');
            let i = 0
            let p = 0
            var data = 0
            var setId = setInterval(() => {
                if (i == data + this.textData[p].length) {
                    data += this.textData[p].length
                    p += 1
                }
                this.text['text' + p] += this.textTest[i]
                i++

                if (i >= this.textTest.length) clearInterval(setId)
            }, 40);
        },
        changePage() {
            this.isChange = !this.isChange
            setTimeout(() => {
                const leftTextContentPArr = document.getElementsByClassName('leftTextContentP')
                Object.values(leftTextContentPArr).map((item, index) => {
                    setTimeout(() => {
                        item.style.opacity = 1
                        item.style.filter = 'blur(0)'
                    }, index * 500)
                })
            }, 40 * this.textTest.length + this.conterText.length * 500);
        },
        hoverDoor() {
            const dom = document.getElementsByClassName('doorImg')[0]
            const domT = document.getElementsByClassName('backDoorLine')[0]
            dom.style.transform = 'rotateY(30deg)  skewY(-3deg)'
            domT.style.width = '1.5vw'
        },
        hoverDoorFalse() {
            const dom = document.getElementsByClassName('doorImg')[0]
            const domT = document.getElementsByClassName('backDoorLine')[0]
            dom.style.transform = 'rotateY(0deg)'
            domT.style.width = '0vw'
        }
    }
}
</script>
<style scoped>
.door {
    position: absolute;
    top: 0;
}

.catIcon {
    position: absolute;
    z-index: 9;
    top: 11vw;
    width: 10vw;
    left: 70vw;
}

/* .backChangePage {
    position: relative;
    z-index: 9;
    top: -14vw;
    left: 18vw;
    width: 6vw;
} */

.leftHua {
    margin-right: 30vw;
}

.backAboutText {
    position: relative;
    left: 13.5vw;
    top: -3vw;
    display: block;
    font-size: .9vw;
    font-weight: 600;
    color: #5fa29e;
}

.backIcon {
    position: relative;
    z-index: 9;
    /* background: red; */
    top: 15vw;
    text-align: center
}

.leftHua {
    width: 10vw;
}

.backTextBackground {
    position: absolute;
    width: 30vw;
    left: 53vw;
    top: 8vw;
}

.backConterText {
    text-align: left;
    /* margin: 0 auto; */
    
    color: rgba(0, 0, 0, .5);
    left: 30vw;
    font-size: .7vw;
    position: relative;
}

.textBackground {
    left: -5vw;
    width: 23vw;
    height: 25vw;
    position: absolute;
    z-index: 1;
    top: -6vw;
    mask-image: url('@/assets/image/about_nagibg.png');
    mask-size: 23vw 25vw;
    mask-repeat: no-repeat;
    background: rgb(255, 255, 255);
    /* 240 239 226 */
}

.leftTextContent {
    z-index: 10;
    position: relative;
    text-align: left;
    left: 18vw;
    top: 2.5vw;
    font-size: .7vw;
    line-height: .6vw;
    color: #6a615b;
}

.pIcon {
    position: relative;
    top: 7vw;
    color: #7c7c7c;
    font-size: .7vw;
    text-shadow: 0.1vw 0.1vw .5vw #fff;
    left: 6vw;
}

.changePage {
    position: absolute;
    left: 3vw;
    background: #6a615b;
    top: 20vw;
    width: 5vw;
    height: 7vw;
    mask-image: url('@/assets/image/changePage.png');
    mask-size: 5vw 7vw;
    mask-repeat: no-repeat;
    opacity: .7;
}

/* .changePageText1{
   
} */
.changePageBox *,
.changePageBox {
    transition: .5s ease;
}

.changePageBox:hover .backLine {
    background: #60a29e;
}

.changePageBox:hover * {
    color: #60a29e;
}

.jiantou {
    width: 5vw;
    height: 5vw;
    mask-image: url("@/assets/image/backjiantou.png");
    mask-repeat: no-repeat;
    mask-size: 4vw 3vw;
    background: #fff;
    top: -14vw;
    left: 68vw;
    position: relative
}

.hover3 * {
    transition: .5s ease;
}

.hover3:hover * {
    color: #ca6a51;
}

.hover3:hover>.changePage {
    /* background: rgba(242,218,208,1); */
    background: #ca6a51;
}

.hover3:hover .line1 {
    /* color: red; */
    width: 7vw;
}

.hover3:hover .line2 {
    /* color: red; */
    width: 5vw;
}

.hover3:hover .line3 {
    /* color: red; */
    width: 7vw;
}

.line1 {
    mask-image: url('@/assets/image/line1.png');
    width: 0vw;
    margin-left: 2.3vw;
    height: 1vw;
    mask-size: 7vw .2vw;
    background: #ca6a51;
    /* background: rgba(242,218,208,1); */
    mask-repeat: no-repeat;
    /* 242 218 208 */
}

.line2 {
    mask-image: url('@/assets/image/line2.png');
    width: 0vw;
    margin-left: 3.2vw;
    height: 1vw;
    mask-size: 7vw .2vw;
    /* background: rgba(242,218,208,1); */
    background: #ca6a51;
    mask-repeat: no-repeat;
    /* 242 218 208 */
}

.line3 {
    mask-image: url('@/assets/image/line3.png');
    width: 0vw;
    margin-left: 2.8vw;
    height: 1vw;
    mask-size: 7vw .2vw;
    background: #ca6a51;
    mask-repeat: no-repeat;
    /* 242 218 208 */
}

.line4 {
    mask-image: url('@/assets/image/line2.png');
    width: 0vw;
    margin-left: 2vw;
    height: 1vw;
    mask-size: 2.5vw .2vw;
    background: #fff;
    mask-repeat: no-repeat;
}

.backDoor {
    width: 6.5vw;
    height: 9.8vw;
    top: 32.4vw;
    position: absolute;
    left: 76.6vw;
    z-index: 99;
    filter: blur(.3vw) drop-shadow(0 0 1vw rgba(0, 0, 0, .5));
    /* background: #fff;
    z-index: 99;
    mask-image: url('@/assets/image/door.png');
    mask-size: 6.5vw 9.8vw;
    mask-repeat: no-repeat; */
}

.backDoorLine {
    width: 0vw;
    height: 5.8vw;
    top: 42.2vw;
    position: absolute;
    transition: 1s ease;
    left: 72.7vw;
    filter: blur(.3vw);
    background: #fff;
    z-index: 99;
    transform: skew(-53deg) rotateX(180deg);
    mask-image: url('@/assets/image/door.png');
    mask-size: 6.5vw 9.8vw;
    mask-repeat: no-repeat;
}

.line5 {
    mask-image: url('@/assets/image/line3.png');
    width: 0vw;
    margin-left: 1.8vw;
    height: 1vw;
    mask-size: 3.5vw .2vw;
    background: #fff;
    mask-repeat: no-repeat;
}

.changePageBox:hover .line4 {
    width: 2.5vw;
}

.changePageBox:hover .line5 {
    width: 3.5vw;
}

.textBox {
    position: relative;
    font-size: .7vw;
    top: 18vw;
    font-weight: 600;
    color: #6a615b;
    text-align: center;
}

.textBoxBack {
    position: relative;
    font-size: .7vw;
    top: -14.5vw;
    width: 7vw;
    font-weight: 600;
    color: #fff;
    text-align: center;
    left: 64vw;
}

.change {
    animation: test 1s forwards;
}

.changeT {
    animation: testT 1s forwards;
}

@keyframes test {
    0% {
        transform: rotateY(0deg);
        filter: blur(0vw);
    }

    50% {
        filter: blur(.3vw);
    }

    100% {
        transform: rotateY(180deg);
        filter: blur(0vw);
    }
}

@keyframes testT {
    0% {
        transform: rotateY(-180deg);
        filter: blur(0vw)
    }

    50% {
        filter: blur(.3vw)
    }

    100% {
        transform: rotateY(0deg);
        filter: blur(0vw)
    }
}

.tNagi {
    width: 5vw;
    left: 8vw;
    position: relative;
    top: 9vw;
}

.rightAllIcon {
    position: relative;
    z-index: 10;
    left: 60vw;
    top: -30vw;
    display: inline-block;
}

.leftTextContentP {
    opacity: 0;
    filter: blur(.3vw);
    transition: all 1s ease;
}

.content {
    position: relative;
    top: -1vw;
    background-color: #dad7cc;
    height: 100vw;
}

.conterText {
    position: relative;
    z-index: 9;
    font-size: .7vw;
  
    left: 32vw;
    top: 2vw;
    line-height: .6vw;
    color: #6a615b;
}

.conterTextP {
    opacity: 0;
    filter: blur(.3vw);
    transition: all 1s ease;
}

.logo {
    position: absolute;
    width: 45vw;
    z-index: 10;
    opacity: .6;
}

.text_about {
    position: relative;
    left: 10vw;
    top: 5vw;
    font-size: .8vw;
}

.xiangao {
    width: 50vw;
    z-index: 10;
    float: right;
    position: relative;
}

.tip {
    /* background: #fff; */
    /* box-shadow: .1vw .1vw 1vw rgba(0, 0, 0, .1); */
    /* width: 20vw;
    height: 7vw; */
    position: absolute;
    right: 17vw;
    top:16vw;
}

.tip p {
    text-align: center;
    font-size: .7vw;
    position: relative;
    top: -4vw;
    left: -3vw;
    z-index: 2;
}

.smallLogo {
    width: 7vw;
    position: relative;
    left: 14vw;
    top: -2vw;
    z-index: 2;
}

.aboutText {
    position: absolute;
    right: 0.5vw;
    top: -3.5vw;
    font-size: .9vw;
    color: #5fa29e;
    font-weight: 600;
}
.aboutText_horizatal{
    writing-mode: vertical-lr;
    position: absolute;
    right: -3.3vw;
    top: -2.5vw;
    height: 10vw;
    font-size: .9vw;
    color: #5fa29e;
    font-weight: 600;
}

.text_about p {
    text-align: center;
    font-weight: 600;
    color: #7b7e76;
    line-height: .9vw;
    font-size: .65vw;
}

.text_about p:nth-child(3) {
    margin-top: 2vw;
    font-family: myFont2;
    /* color: #98bbb3; */
    /* cursor: pointer; */
}

.text_about p span {
    color: #98bbb3;
    font-family: myFont4;
    /* cursor: pointer; */
}

.linglan {
    position: absolute;
    left: 51vw;
    width: 5vw;
    /* scale: 2; */
    /* text-align: center; */
    top: 12vw;
    opacity: 0;
    transition: all 1s ease
}
.top_text_other{
    position: absolute;
    top: 34vw;
    left: 30vw;
}
</style>
